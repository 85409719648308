import { Box, Stack, Typography } from "@mui/joy"
import { COLOR, LISTE_DES_FICHES, PAGE_LINK } from "../../constants/constants"
import LienUtilItem from "./LienUtilItem"

const LiensUtilesZone = () => {
    return (
        <Stack
            width={300}
            gap={2}
        >
            <Typography level="h2" textColor={COLOR.white}>
                Liens Utiles
                <Box
                    width={100}
                    height={8}
                    borderRadius={10}
                    bgcolor={COLOR.white}
                ></Box>
            </Typography>

            <Box>
                {LISTE_DES_FICHES.map((value, index) => (
                    <LienUtilItem 
                        href={`/statistique/${value.fonctionNumber}`}
                        title={value.text}
                        key={index}
                        fontSize={12}
                    />
                ))}

            </Box>
        </Stack>
    )
}

export default LiensUtilesZone