import axios from "axios";
import { RAPPORT_T } from "../constant/objectType";

export const getAllrapports = (): Promise<RAPPORT_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_SISE_API_URL}/rapport/getAllRapports.php`
        ).then(
            ({ data }: { data: RAPPORT_T[] }) => {
                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}