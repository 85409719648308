import axios from "axios";
import { FILTER_CONDITION_T } from "../constant/objectType";

/**
 * Retrieves the filter conditions for a given rapport ID.
 * 
 * @param {number} idrapport - The ID of the rapport to retrieve filter conditions for.
 * @returns {Promise<FILTER_CONDITION_T[]>} A promise that resolves with an array of filter conditions.
 * 
 * @example
 * getRapportFilterCondition(123)
 *   .then((filterConditions) => {
 *     console.log(filterConditions); // Output: Array of FILTER_CONDITION_T objects
 *   })
 *   .catch((error) => {
 *     console.error(error); // Output: "Impossible de se connecter" if connection fails
 *   });
 */
export const getRapportFilterCondition = (idrapport: any): Promise<FILTER_CONDITION_T[]> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/filterCondition/getRapportFilterCondition.php`,
            {
                idRapport: idrapport
            }
        ).then(
            ({ data }: { data: FILTER_CONDITION_T[] }) => {
                console.log('====================================');
                console.log(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}