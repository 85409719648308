import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRapport } from "../../function/getRapport";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Container, Link, Stack, Typography } from "@mui/joy";
import { sendRequest } from "../../function/sendRequest";
import { USE_STATE_T } from "../../constant/objectType";
import { blue } from "@mui/material/colors";
import { getRapportFilterCondition } from "../../function/getRapportFilterCondition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeAlt } from "@fortawesome/free-solid-svg-icons";

const PrintRapport = () => {
    const { idRapport } = useParams();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [request, setrequest]: [request: string | null, setrequest: USE_STATE_T] = useState(null);

    const [rapportTitle, setrapportTitle] = useState('');
    const [documentHeadtext, setdocumentHeadtext] = useState('');
    const [documentFootText, setdocumentFootText] = useState('');
    const [isAllConditionTrue, setisAllConditionTrue] = useState(true);
    const [conditionColor, setconditionColor] = useState("");

    const [headerList, setheaderList]: [headerList: string[], setheaderList: USE_STATE_T] = useState([]);
    const [requestData, setrequestData]: [requestData: [], setrequestData: USE_STATE_T] = useState([]);

    const [filteredElIndex, setfilteredElIndex]:
        [filteredElIndex: number[], setfilteredElIndex: USE_STATE_T] = useState([]);

    useEffect(
        () => {
            getRapport(parseInt(idRapport || "")).then(
                data => {
                    setRowsPerPage(data.pagination);
                    setrequest(data.request);
                    setrapportTitle(data.titreDocument);
                    setdocumentHeadtext(data.enTeteDocument);
                    setdocumentFootText(data.piedDocument);
                    setisAllConditionTrue(data.isAllConditionTrue == 1);
                    setconditionColor(data.couleurLigneFIltre);
                }
            )
        },
        []
    );

    useEffect(
        () => {
            if (request) {
                sendRequest(request).then(
                    ({ data, head }) => {
                        setheaderList(head);
                        setrequestData(data);

                        getRapportFilterCondition(idRapport).then(
                            filterCondition => {
                                let resultatFinal: number[] = [];

                                data.forEach((row, index) => {
                                    let rowResult: boolean[] = [];

                                    filterCondition.forEach((filterEl) => {
                                        switch (filterEl.operation) {
                                            case '=':
                                                if (row[filterEl.field] == filterEl.value) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '!=':
                                                if (row[filterEl.field] != filterEl.value) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '>':
                                                if (row[filterEl.field] > filterEl.value) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '<':
                                                if (row[filterEl.field] < filterEl.value) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '^':
                                                if (
                                                    row[filterEl.field]
                                                        ?.toLowerCase()
                                                        ?.includes(
                                                            filterEl.value
                                                                ?.toLowerCase()
                                                        )
                                                ) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '!^':
                                                if (!(
                                                    row[filterEl.field]
                                                        ?.toLowerCase()
                                                        ?.includes(
                                                            filterEl.value
                                                                ?.toLocaleLowerCase()
                                                        )
                                                )) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '<&':
                                                if (
                                                    row[filterEl.field]
                                                        ?.toLowerCase()
                                                        ?.startsWith(
                                                            filterEl.value
                                                                ?.toLocaleLowerCase()
                                                        )
                                                ) {
                                                    rowResult.push(true);
                                                };
                                                break;

                                            case '&>':
                                                if (
                                                    row[filterEl.field]
                                                        ?.toLowerCase()
                                                        ?.endsWith(
                                                            filterEl.value
                                                                ?.toLocaleLowerCase()
                                                        )
                                                ) {
                                                    rowResult.push(true);
                                                };
                                                break;
                                        }

                                        let toPush = false;

                                        rowResult.forEach(resultatEl => {
                                            if (isAllConditionTrue) {
                                                if (!resultatEl) {
                                                    toPush = false;
                                                }
                                            }
                                            else {
                                                if (resultatEl) {
                                                    toPush = true;
                                                }
                                            }
                                        });

                                        if (toPush) {
                                            resultatFinal.push(index);
                                        }
                                    })
                                })
                                setfilteredElIndex(resultatFinal)
                            }
                        )
                    }
                )
            }
        },
        [request]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Container
            sx={{
                py: 5,
                bgcolor:"white"
            }}
        >
            <Stack
                gap={2}
            >
                <Stack
                    bgcolor={blue[500]}
                    p={2}
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                >
                    <Link href="/repporting/">
                        <Avatar>
                            <FontAwesomeIcon icon={faHomeAlt} />
                        </Avatar>
                    </Link>
                    {documentHeadtext}
                </Stack>

                <Paper
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        bgcolor: blue[100],
                                        "& > *": {
                                            minWidth: 150
                                        }
                                    }}
                                >
                                    {headerList.map((column) => (
                                        <TableCell
                                            key={column}
                                        >
                                            {column}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {requestData

                                    .map((row, dataIndex) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{
                                                    bgcolor: filteredElIndex.find(indexNum => indexNum == dataIndex) ? conditionColor : "white",
                                                }}
                                            >
                                                {headerList.map((column) => {
                                                    const value = row[column];
                                                    return (
                                                        <TableCell key={column}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                    .slice(
                                        page * rowsPerPage, page * rowsPerPage + rowsPerPage
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component={"div"}
                        labelRowsPerPage=""
                        rowsPerPageOptions={[10, 25, 100]}
                        count={requestData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

                <Typography textAlign={"center"} fontWeight={700}>
                    {documentFootText}
                </Typography>
            </Stack>

        </Container>
    );
}


export default PrintRapport