import { Button, Stack } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight, faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { grey, red } from "@mui/material/colors";

const EtapeButtonZone = ({ currentIndex, goToNext, goToBack, finishHandle, visualiserRapportHandle, isRapportCreated }) => {
    return (
        <Stack
            direction={"row"}
            gap={2}
            maxWidthwidth={300}
            width={"100%"}
            sx={{
                "& > *": {
                    gap: 1
                }
            }}
        >
            {
                currentIndex === 1
                    ? (
                        <Button
                            fullWidth
                            sx={{
                                bgcolor: red[900],
                                ":hover": {
                                    bgcolor: red[700],

                                }
                            }}
                            onClick={() => {
                                window.location.href = "/";
                            }}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                            Annuler
                        </Button>
                    )
                    : currentIndex == 8
                        ? (
                            <Button
                                fullWidth
                                sx={{
                                    bgcolor: grey[900],
                                    ":hover": {
                                        bgcolor: grey[700],
                                    }
                                }}
                                onClick={() => {
                                    window.location.href = "/repporting";
                                }}
                            >
                                <FontAwesomeIcon icon={faTimesCircle} />
                                Quitter
                            </Button>
                        )
                        : (
                            <Button
                                fullWidth
                                sx={{
                                    bgcolor: red[900],
                                    ":hover": {
                                        bgcolor: red[700],

                                    }
                                }}
                                onClick={() => !!goToBack && goToBack()}
                            >
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                                Precedent
                            </Button>
                        )
            }
            {
                currentIndex === 7
                    ? (
                        <Button
                            fullWidth
                            onClick={() => !!finishHandle && finishHandle()}
                        >
                            Terminer
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                        </Button>
                    )
                    : currentIndex === 8 && isRapportCreated
                        ? (
                            <Button
                                fullWidth
                                onClick={() => !!visualiserRapportHandle && visualiserRapportHandle()}
                            >
                                Visualiser
                                <FontAwesomeIcon icon={faFile} />
                            </Button>
                        )
                        : (
                            <Button
                                fullWidth
                                onClick={() => !!goToNext && goToNext()}
                            >
                                Suivant
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            </Button>
                        )
            }
        </Stack >
    )
}

export default EtapeButtonZone