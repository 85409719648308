import { Box, Stack, Typography } from '@mui/joy'
import { COLOR, CONTACT, IMAGE } from '../../constants/constants'
import { Card, CardContent } from '@mui/material'
import { faEnvelopeOpen, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import Contact from './Contact'

const ContactZone = () => {
    return (
        <Stack
            width={310}
            gap={2}
        >
            <Typography level="h2" textColor={COLOR.white}>
                Nos contacts
                <Box
                    width={100}
                    height={8}
                    borderRadius={10}
                    bgcolor={COLOR.white}
                ></Box>
            </Typography>

            <Card
                sx={{
                    background: "none",
                    width: "fit-content",
                    padding: "0px",
                    boxShadow: "none",
                    margin: "0px"
                }}

            >
                <CardContent
                    sx={{ p: 0 }}
                >
                    <img
                        src={IMAGE.defaultImage}
                        width={"100%"}
                        alt=''
                    />
                </CardContent>
            </Card>

            <Stack
                gap={1}
            // direction={"row"}
            >
                {CONTACT.tel.map((value, index) => (
                    <Contact text={value} key={index} icon={faPhoneAlt}/>
                ))}
                
                {CONTACT.email.map((value, index) => (
                    <Contact text={value} key={index} icon={faEnvelopeOpen}/>
                ))}
            </Stack>
        </Stack>
    )
}

export default ContactZone