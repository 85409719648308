import { Stack, Typography } from '@mui/joy'
import { Avatar } from '@mui/material'
import { green, grey } from '@mui/material/colors'

const StatistiqueCard = ({ value, name }) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            bgcolor={green[900]}
            p={1}
            gap={1}
            borderRadius={8}
            width={window.innerWidth < 500 ? "100%" : undefined}
        >
            <Avatar
                variant='rounded'
                sx={{
                    aspectRatio: "1/1",
                    width: 50,
                    height: 50
                }}>
                {value}
            </Avatar>
            <Typography
                textColor={grey[50]}
            >
                {name}
            </Typography>
        </Stack>
    )
}

export default StatistiqueCard