import { Button, Drawer, FormControl, MenuItem, Select } from "@mui/material"
import { Box, Stack, Typography } from "@mui/joy"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { blue } from "@mui/material/colors"
import { useState } from "react"

const FilterZone = ({ categorieListe = [], categorieValue = null, setcategorieValue }) => {
    const [showCategorieZone, setshowCategorieZone] = useState(false);

    return (
        <Box>
            <Button
                sx={{
                    gap: 1,
                    textTransform: "initial",
                    bgcolor: blue[700],
                    color: "white",
                    width: "fit-content",
                    minWidth: "fit-content",
                    p: 1,
                    ":hover": {
                        bgcolor: blue[600]
                    },
                    "& >: first-child": {
                        display: "none"
                    },
                    ":hover >: first-child": {
                        display: "initial"
                    },
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                    zIndex: 2
                }}
                onClick={() => setshowCategorieZone(true)}
            >
                <Typography
                    textColor={"white"}
                >
                    Filtrer par categorie
                </Typography>
                <FontAwesomeIcon icon={faFilter} />
            </Button>

            <Drawer
                open={showCategorieZone}
                anchor="right"
                SlideProps={{
                    direction: "up",
                }}

                PaperProps={{
                    sx: {
                        height: "fit-content",
                        // margin: 20,
                        // borderRadius: 10,
                        position: "absolute",
                        marginRight: 2,
                        mt: `calc(100vh - 150px)`,
                        width: 250,
                        p: 2
                    }
                }}
                ModalProps={{
                    onClick: () => setshowCategorieZone(false),
                    onKeyDown: (e) => {
                        const value = e.key;
                        alert(value)
                        setshowCategorieZone(
                            !(value === 'Tab' || value === 'Escape' || value === 'Backspace')
                        )
                    },
                }}

            >
                <Stack>
                    <FormControl >
                        <Select
                            inputProps={{ 'aria-label': 'Without label' }}
                            displayEmpty
                            value={categorieValue}
                            onChange={(e) => setcategorieValue(e.target.value)}
                        >
                            <MenuItem value={null}>Aucune</MenuItem>
                            {
                                categorieListe.map((value, index) => (
                                    <MenuItem
                                        key={index}
                                        value={value.idCategorie}
                                    >{value.nomCategorie}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>

            </Drawer>
        </Box >
    )
}

export default FilterZone