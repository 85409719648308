import { Button } from '@mui/material'
import React from 'react'
import { COLOR } from '../../constants/constants'

const LienUtilItem = ({ href, title, infoTitle, fontSize, fontWeight, bgcolor }) => {
    return (
        <Button
            sx={{
                borderRadius: 0,
                borderBottom: `2px solid ${COLOR.white}`,
                width: "100%",
                bgcolor: bgcolor || COLOR.blue,
                color: COLOR.white,
                fontWeight: fontWeight || 700,
                cursor: !!href ? "pointer" : "default",
                textTransform: "initial",
                fontSize: fontSize,
            }}
            href={href}
            title={infoTitle}
        >
            {title || "lien dynamique"}
        </Button>
    )
}

export default LienUtilItem