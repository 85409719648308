import { Stack, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { identificationBeneficiaires } from '../../functions/fiches/listeBeneficiaires/identificationBeneficiaires';
import { beneficiairesFormes } from '../../functions/fiches/listeBeneficiaires/beneficiairesFormes';
import { nombreEntrepriseHausseBenefice } from '../../functions/fiches/ClasseurGestionDuSiteWeb1/nombreEntrepriseHausseBenefice';
import { financementFier2AGR } from '../../functions/fiches/listeBeneficiaires/financementFier2AGR';
import StatistiqueCard from '../../components/StatistiqueCard/StatistiqueCard';
import { faChartLine, faCity, faHandHoldingDollar, faTriangleExclamation, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR } from '../../constants/constants';

const StatistiqueRapideZone = () => {
    const [nbrBeneficiaire, setnbrBeneficiaire] = useState(null);
    const [nbrPersonneForme, setnbrPersonneForme] = useState(null)
    const [nbrEntrepriseRuralBeneficeHausse, setnbrEntrepriseRuralBeneficeHausse] = useState(null)

    const noNetwork = (
        <Typography
            fontSize={12}
            sx={{
                color: COLOR.red,
            }}
            gap={2}
        >
            <Typography>Veuillez verifier votre connexion </Typography>
            <FontAwesomeIcon icon={faTriangleExclamation} />
        </Typography>
    )

    useEffect(
        () => {
            identificationBeneficiaires().then(
                (data) => {
                    setnbrBeneficiaire(data.length);
                }
            ).catch(
                () => setnbrBeneficiaire(noNetwork)
            );

            beneficiairesFormes().then(
                data => {
                    setnbrPersonneForme(data.length);
                }
            ).catch(
                () => setnbrPersonneForme(noNetwork)
            );

            nombreEntrepriseHausseBenefice().then(
                data => {
                    setnbrEntrepriseRuralBeneficeHausse(data);
                }
            ).catch(
                () => setnbrEntrepriseRuralBeneficeHausse(noNetwork)
            );
        },
        []
    )

    return (
        <Stack
            direction={window.innerWidth > 900 ? "row" : "column"}
            gap={window.innerWidth < 900 ? 3 : 3}
            maxWidth={"100%"}
            justifyContent={"space-between"}
            flexWrap={window.innerWidth < 900 && "wrap"}
        >
            <StatistiqueCard
                title={"Bénéficiaires"}
                value={nbrBeneficiaire}
                icon={faChartLine}
                width={window.innerWidth < 900 && "100%"}
            />
            <StatistiqueCard
                title={"Personnes formés"}
                value={nbrPersonneForme}
                icon={faUser}
                width={window.innerWidth < 900 && "100%"}
            />
            <StatistiqueCard
                title={"Entreprises en hausses de benefice"}
                value={nbrEntrepriseRuralBeneficeHausse}
                icon={faCity}
                width={window.innerWidth < 900 && "100%"}
            />
        </Stack>
    )
}

export default StatistiqueRapideZone