import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, Typography } from '@mui/joy'
import { Button } from '@mui/material'
import { COLOR } from '../../constants/constants'

const LinkButton = ({ content, href = null, style = {}, onClick = null, icon = null, isActive }) => {
    return (
        <Button
            href={href}
            padding={1}
            sx={[{
                borderRadius: "30px",
                bgcolor: COLOR.white,
                fontWeight: 700,
                height: 40,
                textTransform : "initial",
            }, style]}
            onClick={onClick && onClick}

        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
            >
                <Typography
                    textColor={COLOR.blue}
                >{content}</Typography>
                {
                    icon && (
                        <Stack
                            bgcolor={isActive && COLOR.black}
                            width={35}
                            height={35}
                            borderRadius={100}
                            justifyContent={"center"}
                        >
                            <FontAwesomeIcon color={COLOR.blue} icon={icon} />
                        </Stack>
                    )
                }
            </Stack>
        </Button>
    )
}

export default LinkButton