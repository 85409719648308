import { Card, Stack, Typography } from "@mui/joy";
import { PieChart } from "@mui/x-charts";

const DonutModel = (
    { data = [] }:
        {
            data:
            any[]
        }) => {
    return (
        data.length > 0
            ? (
                <Stack>
                    <Card
                        sx={{
                            width: window.innerWidth < 500 ? "100%" : 300,
                            border: "none",
                            bgcolor: "transparent"
                        }}
                    >
                        <PieChart
                            width={window.innerWidth < 500 ? 400 : 400}
                            height={window.innerWidth > 500 ? 300 : 300}
                            series={
                                // [
                                //     {
                                //         data: investissementFier2,
                                //         outerRadius: 115,
                                //         arcLabel: (params) => window.innerWidth > 500 && (params.region ?? ''),
                                //         cornerRadius: 10,
                                //     },
                                //     {
                                //         data: financementFacilitation,
                                //         innerRadius: 130,
                                //         arcLabel: (params) => window.innerWidth > 500 && (params.region ?? ''),
                                //         cornerRadius: 10,
                                //         paddingAngle: 1
                                //     },
                                // ]
                                data
                            }
                            slotProps={{
                                legend: { hidden: true },
                            }}

                            skipAnimation={false}
                        />
                    </Card>
                </Stack>
            )
            : <Typography>Aucune donnée de disponible</Typography>
    )
}

export default DonutModel