import { getClasseurFiche } from "../../API/getClasseurFiche";

/**
 * Interface for the API response data
 */
interface REQUEST_T {
    "Nombre d'entreprises rurales signalant une hause de leurs bénéfices en 2024": number
}

/**
 * Retrieves the number of rural enterprises reporting an increase in profits in 2024.
 * 
 * @returns {Promise<number>} A promise resolving to the number of rural enterprises with increased profits.
 * 
 * @example
 * nombreEntrepriseHausseBenefice().then((result) => {
 *   console.log(`Number of rural enterprises with increased profits: ${result}`);
 * }).catch((error) => {
 *   console.error(`Error fetching data: ${error}`);
 * });
 */
export const nombreEntrepriseHausseBenefice = (): Promise<number> => {
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(4, 11).then(
                (data: REQUEST_T[]) => {
                    const result: number = data[0]["Nombre d'entreprises rurales signalant une hause de leurs bénéfices en 2024"];

                    resolve(result);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}