import { Stack } from "@mui/joy";
import Checkbox from '@mui/joy/Checkbox';
import Sheet from '@mui/joy/Sheet';

const Etape4 = ({ fieldNewNameList = [], fieldToShow = [], setfieldToShow }) => {
    console.log('====================================');
    console.table("Les champs selectionnés",fieldToShow);
    console.log('====================================');
    
    const toogleElementVisibilite = (target, index, value) => {
        if (target.checked) {
            setfieldToShow([
                ...fieldToShow,
                fieldNewNameList[index]
            ])
        }
        else {
            let data = [];

            fieldToShow.map((valueTmp) => {
                if (valueTmp.orignalName != value.orignalName) {
                    data.push(valueTmp);
                }
            });

            setfieldToShow(data);
        }
    }

    return (
        <Stack
            gap={2}
            minWidth={350}
            sx={{
                "& > *": {
                    p: 2,
                    borderRadius: 'md',
                    display: 'flex',
                }
            }}
        >
            {
                fieldNewNameList.map((value, index) => (
                    <Sheet variant="outlined">
                        <Checkbox
                            overlay
                            label={value.editedName}
                            key={index}

                            checked={fieldToShow.find(item => item.orignalName === value.orignalName)}

                            onChange={({ target }) => { toogleElementVisibilite(target, index, value) }}
                        />
                    </Sheet>
                ))
            }
        </Stack >
    )
}

export default Etape4