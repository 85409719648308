import { Stack } from '@mui/joy'
import ObjectifPrincipaleZone from './ObjectifPrincipaleZone'
import ObjectifSpecifiqueZone from './ObjectifSpecifiqueZone'
import GroupeCibleZone from './GroupeCibleZone'
import InterventionZone from './InterventionZone'
import StatistiqueZone from './StatistiqueZone'
import { Helmet } from 'react-helmet'
// import { Helmet } from "react-helmet"

const Accueil = () => {
  return (
    <Stack
      // gap={3}
      borderRadius={25}
      overflow={"hidden"}
    >
      <Helmet>
        <title>Bienvenue sur le site de presentation du projet FIER II</title>

        <meta name="description" content="Bienvenue sur le site de presentation du projet FIER II" />
        <meta property="og:description" content="Bienvenue sur le site de presentation du projet FIER II" />
      </Helmet>

      {/* <PresentationZone /> */}
      <ObjectifPrincipaleZone />
      <ObjectifSpecifiqueZone />
      <GroupeCibleZone />
      <InterventionZone />
      <StatistiqueZone />
    </Stack>
  )
}

export default Accueil