import { getClasseurFiche } from "../../API/getClasseurFiche";

/**
 * Interface representing the structure of a request for beneficiary data.
 */
interface REQUEST_T {
    /**
     * Domain of the training.
     */
    "Domaine de la formation": string,
    /**
     * Number of men trained.
     */
    "Nombre d'hommes formés": number,
    /**
     * Number of women trained.
     */
    "Nombre de femmes formées ": number,
    /**
     * Number of people with disabilities trained.
     */
    "Nombre de personnes en situation de handicap formés ": number,
    /**
     * Total number of people trained.
     */
    "Nombre de personnes formés": number,
    /**
     * Region where the training took place.
     */
    "Région ": string,
    /**
     * Type of training.
     */
    "Type de formation ": string
}

/**
 * Retrieves the list of beneficiaries of a training program.
 * 
 * @returns A promise that resolves to an array of objects containing the beneficiary data.
 * 
 * @example
 * beneficiairesFormes().then((data) => {
 *   console.log(data);
 *   // Output:
 *   // [
 *   //   {
 *   //     "Domaine de la formation": "Domain 1",
 *   //     "Nombre d'hommes formés": 10,
 *   //     "Nombre de femmes formées ": 5,
 *   //     "Nombre de personnes en situation de handicap formés ": 2,
 *   //     "Nombre de personnes formés": 17,
 *   //     "Région ": "Region 1",
 *   //     "Type de formation ": "Type 1"
 *   //   },
 *   //   {
 *   //     "Domaine de la formation": "Domain 2",
 *   //     "Nombre d'hommes formés": 8,
 *   //     "Nombre de femmes formées ": 6,
 *   //     "Nombre de personnes en situation de handicap formés ": 1,
 *   //     "Nombre de personnes formés": 15,
 *   //     "Région ": "Region 2",
 *   //     "Type de formation ": "Type 2"
 *   //   }
 *   // ]
 * });
 */
export const beneficiairesFormes = (): Promise<REQUEST_T[]> => {
    // cette api recupere les beneficiaires du formé
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(6, 18).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}