import { Stack, Typography } from "@mui/joy";
import EtapeIndexZone from "./EtapeIndexZone";
import { useEffect, useState } from "react";
import Etape1 from "./Etape1";
import EtapeButtonZone from "./EtapeButtonZone";
import Etape2 from "./Etape2";
import Etape3 from "./Etape3";
import Etape4 from "./Etape4";
import Etape5 from "./Etape5";
import Etape6 from "./Etape6";
import Etape7 from "./Etape7";
import { FIELDS_NEW_NAME_LISTE_T, FILTER_TABLE_T, USE_STATE_T } from "../../constant/objectType";
import Etape8 from "./Etape8";

const etapeListe: { index: number, title: string }[] = [
    {
        index: 1,
        title: "Source de données"
    },
    {
        index: 2,
        title: "Renommez les champs"
    },
    {
        index: 3,
        title: "Filtre de champ"
    },
    {
        index: 4,
        title: "Choisissez les champs visibles"
    },
    {
        index: 5,
        title: "Filtre et groupement de données"
    },
    {
        index: 6,
        title: "Appliquez des calculs de valeur"
    },
    {
        index: 7,
        title: "Parametrage"
    },
];

const CreateRapport = () => {
    const [currentIndex, setcurrentIndex] = useState(1);

    // step 1
    const [viewName, setviewName] = useState(null);

    // step 2
    const [fieldNewNameList, setfieldNewNameList]:
        [
            fieldNewNameList: FIELDS_NEW_NAME_LISTE_T[],
            setfieldNewNameList: USE_STATE_T
        ] = useState([]);

    // step 3
    const [filterTable, setfilterTable]:
        [
            filterTable: FILTER_TABLE_T[],
            setfilterTable: USE_STATE_T
        ] = useState([]);
    const [fildColor, setfildColor] = useState("#0A480E");
    const [isAllTrue, setisAllTrue] = useState(true);

    // setp 4 
    const [fieldToShow, setfieldToShow] = useState([]);

    // step 5
    const [filterByValue, setfilterByValue] = useState(null);
    const [groupeByValue, setgroupeByValue] = useState(null);
    const [isCroissant, setisCroissant] = useState(true);

    // step 6
    const [functionTable, setfunctionTable] = useState([]);

    // step 7
    const [rapportCategorie, setrapportCategorie] = useState(null);
    const [rapportTitle, setrapportTitle] = useState(null);
    const [rapportEntete, setrapportEntete] = useState(null);
    const [rapportPied, setrapportPied] = useState(null);
    const [rapportName, setrapportName] = useState(null);
    const [nbrLinePerPage, setnbrLinePerPage] = useState(25);
    const [rapportVisibility, setrapportVisibility] = useState(`public`);

    // step 8
    const [isRapportCreated, setisRapportCreated] = useState(null);
    const [idRapport, setidRapport] = useState(0);

    const goToNext = () => {
        let result = false;

        switch (currentIndex) {
            case 1:
                result = !!viewName;
                break;

            case 2:
                result = true;
                break;

            case 3:
                result = true;
                break;

            case 4:
                result = fieldToShow.length > 0;
                break;

            case 5:
                result = !!filterByValue;
                break;

            case 6:
                result = true;
                break;

            case 7:
                result = !!rapportCategorie
                    && !!rapportTitle
                    && !!rapportEntete
                    && !!rapportPied
                    && !!rapportName
                    && !!rapportVisibility;
                break;
        }

        result ? setcurrentIndex(currentIndex + 1) : alert("Cette etape est obligatoire");
    }

    const goToBack = () => {
        setcurrentIndex(currentIndex - 1);
    }

    const finishHandle = () => {
        let result = false;

        switch (currentIndex) {
            case 7:
                result = !!rapportCategorie
                    && !!rapportTitle
                    && !!rapportEntete
                    && !!rapportPied
                    && !!rapportName
                    && !!rapportVisibility;
                break;
        }

        if (!result) {
            alert("Cette etape est obligatoire");
        }
        else {
            setcurrentIndex(currentIndex + 1);
        }
    }

    const visualiserRapportHandle = () => {
        window.location.href = `/repporting/visualiserRapport/${idRapport}`;
    }



    useEffect(
        () => {
            switch (currentIndex) {
                case 1:
                    setviewName(null);
                    break;

                case 2:
                    setfieldNewNameList([]);
                    setfieldToShow([])
                    break;

                case 3:
                    setfilterTable([]);
                    break;

                case 5:
                    setfilterByValue(null);
                    setgroupeByValue(null);
                    break;

                case 6:
                    setfunctionTable([]);
                    break;
            }
        },
        [currentIndex]
    );

    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"white"}
        >
            <Stack
                py={10}
                gap={2}
                alignItems={"center"}
            >
                <Typography
                    level="h3"
                >
                    {
                        currentIndex <= etapeListe.length && (etapeListe.find(value => value.index === currentIndex))?.title
                    }
                </Typography>

                {
                    currentIndex === 1
                        ? <Etape1
                            viewName={viewName}
                            setViewName={setviewName}
                        />
                        : currentIndex === 2
                            ? <Etape2
                                viewName={viewName}
                                fieldNewNameList={fieldNewNameList}
                                setfieldNewNameList={setfieldNewNameList}
                            />
                            : currentIndex === 3
                                ? <Etape3
                                    fieldNewNameList={fieldNewNameList}
                                    filterTable={filterTable}
                                    setfilterTable={setfilterTable}
                                    setfildColor={setfildColor}
                                    fildColor={fildColor}
                                    isAllTrue={isAllTrue}
                                    setisAllTrue={setisAllTrue}
                                />
                                : currentIndex === 4
                                    ? <Etape4
                                        fieldNewNameList={fieldNewNameList}
                                        fieldToShow={fieldToShow}
                                        setfieldToShow={setfieldToShow}
                                    />
                                    : currentIndex === 5
                                        ? <Etape5
                                            fieldToShow={fieldToShow}
                                            filterByValue={filterByValue}
                                            setfilterByValue={setfilterByValue}
                                            groupeByValue={groupeByValue}
                                            setgroupeByValue={setgroupeByValue}
                                            isCroissant={isCroissant}
                                            setisCroissant={setisCroissant}
                                        />
                                        : currentIndex === 6
                                            ? <Etape6
                                                fieldNewNameList={fieldNewNameList}
                                                functionTable={functionTable}
                                                setfunctionTable={setfunctionTable}
                                            />
                                            : currentIndex === 7
                                                ? <Etape7
                                                    rapportCategorie={rapportCategorie}
                                                    setrapportCategorie={setrapportCategorie}
                                                    rapportTitle={rapportTitle}
                                                    setrapportTitle={setrapportTitle}
                                                    rapportPied={rapportPied}
                                                    setrapportPied={setrapportPied}
                                                    rapportEntete={rapportEntete}
                                                    setrapportEntete={setrapportEntete}
                                                    rapportName={rapportName}
                                                    setrapportName={setrapportName}
                                                    nbrLinePerPage={nbrLinePerPage}
                                                    setnbrLinePerPage={setnbrLinePerPage}
                                                    rapportVisibility={rapportVisibility}
                                                    setrapportVisibility={setrapportVisibility}
                                                />
                                                : <Etape8
                                                    fieldNewNameList={fieldToShow}
                                                    filterByValue={filterByValue}
                                                    groupeByValue={groupeByValue}
                                                    isCroissant={isCroissant}
                                                    viewName={viewName}
                                                    functionTable={functionTable}
                                                    categorie={rapportCategorie}
                                                    couleurLigneFiltre={fildColor}
                                                    enTeteDocument={rapportEntete}
                                                    piedDocument={rapportPied}
                                                    titreDocument={rapportTitle}
                                                    rapportName={rapportName}
                                                    isAllConditionTrue={isAllTrue}
                                                    pagination={nbrLinePerPage}
                                                    filterTable={filterTable}
                                                    visibilite={rapportVisibility}
                                                    isRapportCreated={isRapportCreated}
                                                    setisRapportCreated={setisRapportCreated}
                                                    setidRapport={setidRapport}
                                                />
                }

                <EtapeButtonZone
                    currentIndex={currentIndex}
                    goToNext={goToNext}
                    goToBack={goToBack}
                    finishHandle={finishHandle}
                    visualiserRapportHandle={visualiserRapportHandle}
                    isRapportCreated={isRapportCreated}
                />
            </Stack>

            <EtapeIndexZone
                currentIndex={currentIndex}
                etapeListe={etapeListe}
            />
        </Stack>
    )
}

export default CreateRapport