import { Box } from '@mui/joy';
import TableModel from '../../components/TableModel/TableModel';
import { useEffect, useState } from 'react';
import { nombreInstallationCommercialisationRemisEnEtat } from '../../functions/fiches/suiviIndicateurs/nombreInstallationCommercialisationRemisEnEtat';
import { entrepriseSignalantUneHausseDeBenefices } from '../../functions/fiches/suiviIndicateurs/entrepriseSignalantUneHausseDeBenefices';
import { personneAccessServicesFinanciers } from '../../functions/fiches/suiviIndicateurs/personneAccessServicesFinanciers';

const TableZone = ({ fonctionNumber }) => {
    const [tableHeader, settableHeader] = useState([]);
    const [tableData, settableData] = useState([]);
    const [tableTitle, settableTitle] = useState(null);

    useEffect(
        () => {
            settableHeader([]);
            settableData([]);
            settableTitle(null);
            switch (fonctionNumber) {
                case 1:
                    settableHeader([
                        "Commercialistaion construite",
                        "Commercialistaion mis en état",
                        "Transformation et stockage construite",
                        "Transformation et stockage mis en état",
                        "Régions",
                    ]);

                    settableTitle("Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état");

                    nombreInstallationCommercialisationRemisEnEtat().then(
                        (data) => {
                            const dataTmp = [];

                            data.map(value => {
                                dataTmp.push(Object.values(value));
                            });

                            settableData(dataTmp);
                        }
                    );
                    break;

                case 3:
                    settableHeader([
                        "Entreprises rurales signalant une hausse de leurs bénéfices",
                        "Personnes dont le revenu a augmenté",
                        "Personnes dont la résilience s’est renforcée",
                        "Régions",
                    ]);

                    settableTitle("Entreprises rurales appuyées signalant une hausse de leurs bénéfices");

                    entrepriseSignalantUneHausseDeBenefices().then(
                        (data) => {
                            const dataTmp = [];
            
                            data.map(iterator=>{
                                dataTmp.push([
                                    iterator["Nombre d'entreprises rurales appuyées signalant une hausse de leurs bénéfices"],
                                    iterator["Nombre de Personnes dont le revenu a augmenté"],
                                    iterator["Nombre de personnes dont la résilience s’est renforcée "],
                                    iterator.Région
                                ]);
                            });
            
                            settableData(dataTmp);
                        }
                    );
                    break;

                case 5:
                    settableHeader([
                        "Nombre de femmes ayant accès à des services financiers (crédit) dans les zones rurales",
                        "Nombre d'hommes ayant accès à des services financiers (crédit) dans les zones rurales",
                        "Nombre de personnes en situation de handicap ayant accès à des services financiers (crédit)dans les zones rurales",
                        "Nombre de femmes ayant accès à des services financiers (épargne) dans les zones rurales", ,
                        "Régions",
                    ]);
                    settableTitle("Nombre de Personnes ayant accès à des services financiers dans les zones rurales");

                    personneAccessServicesFinanciers().then(
                        data => {
                            const dataTmp = [];

                            data.map(value=>{
                                dataTmp.push([
                                    value['Nombre de femmes ayant accès à des services financiers (crédit) dans les zones rurales'],
                                    value['Nombre d\'hommes ayant accès à des services financiers (crédit) dans les zones rurales'],
                                    value['Nombre de personnes en situation de handicap ayant accès à des services financiers (crédit)dans les zones rurales'] || 0,
                                    value['Nombre de femmes ayant accès à des services financiers (épargne) dans les zones rurales'],
                                    value.Région
                                ])
                            })

                            for (const iterator of data) {
                                let tmp = Object.values(iterator);
                                dataTmp.push([
                                    
                                ]);
                            }

                            settableData(dataTmp);
                        }
                    )
                    break;
            }
        },
        [fonctionNumber]
    )

    return (
        <Box

            overflow={"scroll"}
            p={2}
            boxShadow={`inset 5px 10px 10px grey`}
            borderRadius={30}
        >
            <TableModel
                header={tableHeader}
                tableTilte={tableTitle}
                data={tableData}
            />
        </Box>
    )
}

export default TableZone