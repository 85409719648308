import { faComment } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalClose, Sheet, Stack, Typography } from '@mui/joy'
import { OptionItem } from './OptionItem';
import { DEMANDE_FICHE } from '../../../constants/constants';
import { useContext } from 'react';
import { FormNumberContext } from '../../../context/FormNumberContext';

const DemandeOptionZone = () => {
  const { isDemandeOptionZone, setisDemandeOptionZone } = useContext(FormNumberContext);

  return (
    <Modal
      open={isDemandeOptionZone}
      onClose={() => setisDemandeOptionZone(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: 20,
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />

        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
          pr={5}
        >
          Choisissez une option
        </Typography>

        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          gap={2}
        >
          {
            DEMANDE_FICHE.map((value, index) => (
              <OptionItem
                title={value.nom}
                hoverTitle={value.hoverTitle}
                icone={value.icon}
                index={index + 1}
              />
            ))
          }
        </Stack>
      </Sheet>
    </Modal>
  )
}

export default DemandeOptionZone