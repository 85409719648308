import { Box, Input, Stack, Typography } from "@mui/joy"
import { COLOR } from "../../constants/constants"
import { faMailBulk } from "@fortawesome/free-solid-svg-icons"
import ButtonIcon from "../ButtonIcon/ButtonIcon"

const NewsLetterZone = () => {
    return (
        <Box
            width={310}
        >
            <Typography level="h3" textColor={COLOR.white}>
                Newsletter
                <Box
                    width={100}
                    height={8}
                    borderRadius={10}
                    bgcolor={COLOR.white}
                ></Box>
            </Typography>

            <Typography
                textColor={COLOR.white}
                textAlign={"justify"}
            >
                Souscrivez pour vous tenir informer des nouveautés et de l'avancer du programme.
            </Typography>

            <Stack
                gap={1}
            >
                <Stack
                    direction={"row"}
                    bgcolor={COLOR.white}
                    p={1}
                    gap={1}
                >
                    <Input
                        sx={{
                            borderRadius: 0,
                            border: "none",
                            outline: "none"
                        }}
                        placeholder="Votre adress mail"
                    />
                    <ButtonIcon icon={faMailBulk} />
                </Stack>
            </Stack>
        </Box>
    )
}

export default NewsLetterZone