import axios from "axios";

/**
 * Sends a request to the API to execute a query on a database.
 * 
 * @param {string} request - The SQL query to be executed.
 * @param {string} [bd_url] - The URL of the database. Defaults to the value of `process.env.REACT_APP_SISE_BD_URL`.
 * @param {string} [bd_name] - The name of the database. Defaults to the value of `process.env.REACT_APP_SISE_BD_NAME`.
 * @param {string} [bd_login] - The login credentials for the database. Defaults to the value of `process.env.REACT_APP_SISE_BD_LOGIN`.
 * @param {string} [bd_pass] - The password for the database. Defaults to the value of `process.env.REACT_APP_SISE_BD_PASS`.
 * 
 * @returns {Promise<{ head: string[], data: {}[] }>} A promise that resolves with an object containing the header list and the query results.
 * 
 * @example
 * sendRequest("SELECT * FROM users")
 *   .then((result) => {
 *     console.log(result.head); // Output: ["id", "name", "email"]
 *     console.log(result.data); // Output: [{ id: 1, name: "John Doe", email: "john@example.com" }, ...]
 *   })
 *   .catch((error) => {
 *     console.error(error); // Output: "Requete impossible"
 *   });
 */
export const sendRequest = (
    request: string,
    bd_url: string | undefined = process.env.REACT_APP_SISE_BD_URL,
    bd_name: string | undefined = process.env.REACT_APP_SISE_BD_NAME,
    bd_login: string | undefined = process.env.REACT_APP_SISE_BD_LOGIN,
    bd_pass: string | undefined = process.env.REACT_APP_SISE_BD_PASS,
): Promise<{ head: string[]; data: any[]; }> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/rapport/sendRequest.php`,
            {
                bd_url: bd_url,
                bd_name: bd_name,
                bd_login: bd_login,
                bd_pass: bd_pass,
                request: request
            }
        ).then(
            ({ data }: { data: any[] }) => {
                console.log('====================================');
                console.table(data);
                console.log('====================================');

                const headerList = Object.keys(data[0]);
                resolve({
                    head: headerList,
                    data: data
                });
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Requete impossible");
            }
        )
    })
}