import axios from "axios"

/**
 * Interface representing a single classeur object.
 */
interface REQUEST_T {
    Id: number,
    "Libelle classeur": string,
    "Note classeur": string,
    "fiches classeur": {
        "Code feuille": number,
        Id: number,
        "Libelle feuille": string,
        "fiche format link": string,
        "fiche link": string,
    }[],
}

/**
 * Retrieves a list of all classeurs from the API.
 *
 * @returns A promise that resolves to an array of REQUEST_T objects, each representing a classeur.
 * @example
 * const classeurs = await getAllClasseurs();
 * console.log(classeurs); // Output: [{ Id: 1, "Code classeur": 1, ... }, { Id: 2, "Code classeur": 2, ... }]
 */
export const getAllClasseurs = (): Promise<REQUEST_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/classeur`
        ).then(
            ({data}) => {
                const response: REQUEST_T[] = data.data.classeur;

                console.log('====================================');
                console.log("Liste des classeurs", response);
                console.log('====================================');

                resolve(response);
            }
        ).catch(
            (e) => {
                console.log('====================================');
                console.error(e);
                console.log('====================================');
                reject(e);
            }
        )
    })
}