import { Box, Drawer, Stack, Typography } from '@mui/joy'
import { COLOR, DEMANDE_FICHE, IMAGE, PAGE_LINK } from '../../constants/constants'
import { Button, Divider } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faComment, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import FormModel from '../FormModel/FormModel'
import DemandeOptionZone from './DemandeOptionZone'
import { FormNumberContext } from '../../context/FormNumberContext'

const NavBar = () => {
    const [open, setOpen] = useState(false);
    const [isDemandeOptionZone, setisDemandeOptionZone] = useState(false);

    //1 pour soumission de dossier, 2 pour depot de pleinte
    const [formNumber, setformNumber] = useState(null as null | number);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const page = useLocation();

    const DrawerList = (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            px={1}
        >
            <Stack>
                {
                    PAGE_LINK.map((value, index) => (
                        <Button
                            href={value.link}
                            key={index}
                            sx={{
                                // bgcolor: COLOR.blue,
                                color: page.pathname == value.link ? COLOR.blue : COLOR.darkGrey,
                                textTransform: "initial",
                                width: "fit-content",
                                gap: 1,
                                ":hover": {
                                    backgroundColor: COLOR.blue,
                                    color: COLOR.white
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={value.icon} />
                            {value.text}
                        </Button>
                    ))
                }
            </Stack>

            <Divider />

            <Stack>
                {
                    DEMANDE_FICHE.map((value, index) => (
                        <Button
                            sx={{
                                // bgcolor: COLOR.blue,
                                color: COLOR.black,
                                textTransform: "initial",
                                width: "fit-content",
                                gap: 1,
                                borderRadius: 0,
                                ":hover": {
                                    borderBottom: `1px solid`
                                }
                            }}
                            onClick={() => {
                                setformNumber(index);
                            }}
                        >
                            {value.nom}
                            <FontAwesomeIcon icon={faFileDownload} />
                        </Button>
                    ))
                }
            </Stack>
        </Box>
    );

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={1}
            paddingX={"5%"}
            pt={1}
        >
            <a
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    maxWidth: 180
                }}
                href={PAGE_LINK[0].link}
            >
                <img
                    src={IMAGE.defaultImage}
                    style={{
                        width: 80
                    }}
                />
                <Typography
                    fontSize={10}
                    fontWeight={700}
                    textColor={COLOR.white}
                > Deuxième projet de Formation professionnelle, Insertion et Appui à l'Entrepreneuriat des jeunes Ruraux </Typography>
            </a>

            {
                window.innerWidth > 1000
                    ? (
                        <>
                            <Stack
                                direction={"row"}
                                gap={0.5}
                            >
                                {
                                    PAGE_LINK.map((value, index) => (
                                        <Button
                                            href={value.link}
                                            key={index}
                                            sx={{
                                                borderTop: page.pathname == value.link ? `2px solid ${COLOR.white}` : "",
                                                color: COLOR.white,
                                                textTransform: "initial",
                                                ":hover": {
                                                    transform: `scale(1.1)`
                                                },
                                                gap: 1,
                                                borderRadius: 0
                                            }}
                                        >
                                            {value.text}
                                        </Button>
                                    ))
                                }
                            </Stack>

                            <Button
                                sx={{
                                    gap: 1,
                                    bgcolor: COLOR.white,
                                    textTransform: "initial",
                                    borderRadius: 15
                                }}
                                onClick={() => setisDemandeOptionZone(true)}
                            >
                                <Typography>Effectuer une demande</Typography>
                            </Button>
                        </>
                    )
                    : (
                        <Box>
                            <Button
                                onClick={toggleDrawer(true)}
                                sx={{
                                    textTransform: "initial",
                                    color: COLOR.black,
                                    bgcolor: COLOR.white,
                                    borderRadius: 15,
                                    gap: 1
                                }}
                            >
                                Menu
                                <FontAwesomeIcon icon={faBars} />
                            </Button>
                            <Drawer
                                open={open}
                                anchor='right'
                                onClose={toggleDrawer(false)}
                            >
                                {DrawerList}
                            </Drawer>
                        </Box>
                    )
            }

            <FormNumberContext.Provider value={{
                formNumber,
                setformNumber,
                isDemandeOptionZone,
                setisDemandeOptionZone
            }}>
                <FormModel />

                <DemandeOptionZone />
            </FormNumberContext.Provider>

        </Stack>
    )
}

export default NavBar