import { Box } from '@mui/joy'
import { GraphModel } from '../../components/GraphModel/GraphModel'
import { useEffect, useState } from 'react'
import { nombrePrestataireDeServiceFinancierSoutenueDansLaMiseEnPlaceDeStrategie } from '../../functions/fiches/suiviIndicateurs/nombrePrestataireDeServiceFinancierSoutenueDansLaMiseEnPlaceDeStrategie'
import { beneficiairesFormes } from '../../functions/fiches/listeBeneficiaires/beneficiairesFormes'
import { financementFier2AGR } from '../../functions/fiches/listeBeneficiaires/financementFier2AGR'
import { COLOR } from '../../constants/constants'
import { personneResilienceRenforcee } from '../../functions/fiches/ClasseurGestionDuSiteWeb1/personneResilienceRenforcee'

const GrapheZone = ({ fonctionNumber }) => {
    const [graphXaxis, setgraphXaxis] = useState([])
    const [graphYaxis, setgraphYaxis] = useState([])
    const [graphDataMultiLine, setgraphDataMultiLine] = useState([{ data: [] }]);
    const [graphTitle, setgraphTitle] = useState(null)
    const [isShowArea, setisShowArea] = useState(true);
    const [isGraph, setisGraph] = useState(true);
    const [dataIsMultiLine, setdataIsMultiLine] = useState(false);

    useEffect(
        () => {
            setgraphXaxis([]);
            setgraphTitle(null);
            setisGraph(true);

            switch (fonctionNumber) {
                case 2:
                    setisGraph(true);
                    setgraphYaxis([]);
                    setdataIsMultiLine(false);
                    setgraphTitle("Nombres de prestataires de services financiers soutenus dans la mise en place de stratégies de desserte, et de services et produits financiers dans les zones rurales");

                    nombrePrestataireDeServiceFinancierSoutenueDansLaMiseEnPlaceDeStrategie().then(
                        (data) => {
                            let xAxisTmp = [];
                            let yAxisTmp = [];

                            data.map(value => {
                                xAxisTmp.push(value['Région']);
                                yAxisTmp.push(value['Nombre de prestataires de services financiers soutenus dans la mise en place de stratégies de desserte, et de services et produits financiers dans les zones rurales']);

                            })

                            setgraphXaxis(xAxisTmp);
                            setgraphYaxis(yAxisTmp);
                        }
                    )
                    break;

                case 4:
                    setgraphXaxis(["Segou", "Sikasso", "Koulikoro", "Kayes"]);
                    setgraphTitle("Beneficiaires formes par region et par secteur dactivite");
                    setisGraph(true);

                    setdataIsMultiLine(true);

                    beneficiairesFormes().then(
                        data => {
                            setgraphDataMultiLine([{ data: [] }]);

                            let dataTmp = [
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                            ];

                            data.map(iterator => {
                                dataTmp[0][0] = dataTmp[0][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[1][0] = dataTmp[1][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[2][0] = dataTmp[2][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[3][0] = dataTmp[3][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[4][0] = dataTmp[4][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);

                                dataTmp[0][1] = dataTmp[0][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[1][1] = dataTmp[1][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[2][1] = dataTmp[2][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[3][1] = dataTmp[3][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[4][1] = dataTmp[4][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);

                                dataTmp[0][2] = dataTmp[0][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[1][2] = dataTmp[1][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[2][2] = dataTmp[2][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[3][2] = dataTmp[3][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[4][2] = dataTmp[4][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);

                                dataTmp[0][3] = dataTmp[0][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[1][3] = dataTmp[1][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[2][3] = dataTmp[2][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[3][3] = dataTmp[3][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                                dataTmp[4][3] = dataTmp[4][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                            });

                            setgraphDataMultiLine(
                                [
                                    {
                                        data: dataTmp[0],
                                        label: "Formation en entreprenaraiat"
                                    },
                                    {
                                        data: dataTmp[1],
                                        label: "Apprentissage"
                                    },
                                    {
                                        data: dataTmp[2],
                                        label: "Formation par alternance"
                                    },
                                    {
                                        data: dataTmp[3],
                                        label: "Formation technique"
                                    },
                                    {
                                        data: dataTmp[4],
                                        label: "Alphabetisation fonctionnelle"
                                    }
                                ]
                            )
                        }
                    );

                    break;

                case 8:
                    setgraphXaxis(["Sikasso", "Koulikoro", "Kayes", "Segou"]);
                    setgraphTitle("Montant financement FIER 2 AGR");
                    setisGraph(true);

                    setdataIsMultiLine(true);

                    financementFier2AGR().then(
                        data => {
                            setgraphDataMultiLine([{ data: [] }]);

                            let dataTmp = [
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                            ];

                            data.map(iterator => {
                                let montant1 = iterator['Montant de l\'investissement FIER 2'];
                                let montant2 = iterator['Montant du financement par facilitation'];

                                dataTmp[0][0] = dataTmp[0][0] + ((iterator['Region '] == "Sikasso" || iterator['Region '] == "Sokasso") ? parseInt(montant1) : 0);
                                dataTmp[1][0] = dataTmp[1][0] + ((iterator['Region '] == "Sikasso" || iterator['Region '] == "Sokasso") ? parseInt(montant2) : 0);

                                dataTmp[0][1] = dataTmp[0][1] + (iterator['Region '] == "Koulikoro" ? parseInt(montant1) : 0);
                                dataTmp[1][1] = dataTmp[1][1] + (iterator['Region '] == "Koulikoro" ? parseInt(montant2) : 0);

                                dataTmp[0][2] = dataTmp[0][2] + (iterator['Region '] == "Kayes" ? parseInt(montant1) : 0);
                                dataTmp[1][2] = dataTmp[1][2] + (iterator['Region '] == "Kayes" ? parseInt(montant2) : 0);

                                dataTmp[0][3] = dataTmp[0][3] + (iterator['Region '] == "Segou" ? parseInt(montant1) : 0);
                                dataTmp[1][3] = dataTmp[1][3] + (iterator['Region '] == "Segou" ? parseInt(montant2) : 0);
                            });

                            setgraphDataMultiLine(
                                [
                                    {
                                        data: dataTmp[0],
                                        label: "Montant de l'investissement FIER 2",
                                        stack: 'A',
                                        color: COLOR.blue
                                    },
                                    {
                                        data: dataTmp[1],
                                        label: "Montant du financement par facilitation",
                                        stack: 'A',
                                        color: COLOR.green
                                    },

                                ]
                            )
                        }
                    );
                    break;

                case 10:
                    setisGraph(true);
                    setgraphYaxis([]);
                    setdataIsMultiLine(false);
                    setgraphTitle("Nombre de personnes dont la résilience s’est renforcée selon les zones d'interventions");

                    personneResilienceRenforcee().then(
                        (data) => {
                            let xAxisTmp = [];
                            let yAxisTmp = [];

                            data.map(value => {
                                xAxisTmp.push(value["Zones d'intervention"]);
                                yAxisTmp.push(value["Nombre de personne dont la résilience s'est renforcée"]);

                            })

                            setgraphXaxis(xAxisTmp);
                            setgraphYaxis(yAxisTmp);
                        }
                    )
                    break;
            }
        },
        [fonctionNumber]
    )

    return (
        <Box
            overflow={"scroll"}
            borderRadius={10}
        >
            <GraphModel
                graphTilte={graphTitle}
                xAxis={graphXaxis}
                yAxis={graphYaxis}
                isGraph={isGraph}
                isShowArea={isShowArea}
                dataIsMultiLine={dataIsMultiLine}
                maxWidth={"100%"}
                dataMulitiline={graphDataMultiLine}
                bgColor={COLOR.lightblue}
                borderRadius={2}
            />
        </Box>
    )
}

export default GrapheZone