import { Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import { axisClasses, LineChart } from '@mui/x-charts'
import { Paper } from '@mui/material'

export const CourbeModel = ({
    xAxis = [],
    yAxis = [],
    graphTilte = null,
    isShowArea = true,
    maxWidth,
    height,
    bgColor,
    borderRadius
}) => {
    return (
        <Paper
            elevation={5}
            sx={{
                borderRadius: borderRadius || 5,
                p: 2,
                background: !!bgColor ? bgColor : `linear-gradient(45deg, ${COLOR.lightGrey}, ${COLOR.lightblue})`,
                maxWidth: maxWidth || 500,
                border: `2px solid`
            }}
        >
            <LineChart
                xAxis={[{
                    data: xAxis,
                    scaleType: "band",
                }]}

                series={[
                    {
                        data: yAxis,
                        color: COLOR.blue,
                        area: { isShowArea }
                    },
                ]}
                height={height || 300}
                sx={{
                    [`& .${axisClasses.left}`]: {
                        line: {
                            stroke: COLOR.blue,
                            strokeWidth: 3
                        },
                        text: {
                            fill: COLOR.blue,
                            fontWeight: 700
                        },
                    },
                    [`& .${axisClasses.tick}`]: {
                        display: "none"
                    },
                }}
            />

            <Typography
                fontWeight={700}
                textAlign={"center"}
            >
                {graphTilte}
            </Typography>
        </Paper>
    )
}