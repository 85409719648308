import { faFileDownload, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, Typography } from '@mui/joy'
import { COLOR } from '../../../constants/constants'
import { useContext } from 'react'
import { FormNumberContext } from '../../../context/FormNumberContext'

export const OptionItem = (
    {
        title,
        icone = undefined,
        hoverTitle,
        index
    }:
        {
            title: string,
            icone: IconDefinition | undefined,
            hoverTitle?: string,
            index: number
        }
) => {
    const { setformNumber } = useContext(FormNumberContext);

    return (
        <Stack
            alignItems={"center"}
            width={150}
            height={150}
            bgcolor={COLOR.lightblue}
            borderRadius={15}
            overflow={"hidden"}
            title={hoverTitle}
            onClick={() => {
                setformNumber(index);
            }}

            sx={{
                transition: `all 0.09s`,
                cursor: `pointer`,
                ":hover": {
                    transform: `scale(1.05)`
                }
            }}
        >
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                bgcolor={COLOR.blue}
                fontSize={70}
            >
                <FontAwesomeIcon
                    icon={icone || faFileDownload}
                    color={COLOR.white}
                />
            </Stack>
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                height={50}
            >
                <Typography
                    fontWeight={700}
                    textAlign={"center"}
                >
                    {title || `title`}
                </Typography>
            </Stack>
        </Stack>
    )
}