import { Box, Card, CardCover, Stack, Typography } from '@mui/joy';
import { COLOR, IMAGE } from '../../constants/constants';
import { useEffect, useState } from 'react';
import { GraphModel } from '../../components/GraphModel/GraphModel';
import { motion } from "framer-motion"
import { financementFier2AGR } from '../../functions/fiches/listeBeneficiaires/financementFier2AGR';

const ObjectifPrincipaleZone = () => {
    const [graphDataMultiLine, setgraphDataMultiLine] = useState([{ data: [] }]);

    useEffect(
        () => {
            financementFier2AGR().then(
                data => {
                    setgraphDataMultiLine([{ data: [] }]);

                    let dataTmp = [
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                    ];

                    data.map(iterator => {
                        let montant1 = iterator['Montant de l\'investissement FIER 2'];
                        let montant2 = iterator['Montant du financement par facilitation'];
  
                        dataTmp[0][0] = dataTmp[0][0] + ((iterator['Region '] == "Sikasso" || iterator['Region '] == "Sokasso") ? parseInt(montant1) : 0);
                        dataTmp[1][0] = dataTmp[1][0] + ((iterator['Region '] == "Sikasso" || iterator['Region '] == "Sokasso") ? parseInt(montant2) : 0);

                        dataTmp[0][1] = dataTmp[0][1] + (iterator['Region '] == "Koulikoro" ? parseInt(montant1) : 0);
                        dataTmp[1][1] = dataTmp[1][1] + (iterator['Region '] == "Koulikoro" ? parseInt(montant2) : 0);

                        dataTmp[0][2] = dataTmp[0][2] + (iterator['Region '] == "Kayes" ? parseInt(montant1) : 0);
                        dataTmp[1][2] = dataTmp[1][2] + (iterator['Region '] == "Kayes" ? parseInt(montant2) : 0);

                        dataTmp[0][3] = dataTmp[0][3] + (iterator['Region '] == "Segou" ? parseInt(montant1) : 0);
                        dataTmp[1][3] = dataTmp[1][3] + (iterator['Region '] == "Segou" ? parseInt(montant2) : 0);
                    });

                    setgraphDataMultiLine(
                        [
                            {
                                data: dataTmp[0],
                                label: "Montant de l'investissement FIER 2",
                                stack: 'A',
                                color: COLOR.blue
                            },
                            {
                                data: dataTmp[1],
                                label: "Montant du financement par facilitation",
                                stack: 'A',
                                color: COLOR.green
                            },

                        ]
                    )
                }
            )
        },
        []
    )


    return (
        <Stack
            gap={2}
            bgcolor={COLOR.white}
            p={3}
        >
            <Box
                width={"fit-content"}
                p={1}
            >
                <Typography
                    textColor={COLOR.blue}
                    level='h2'
                >
                    Objectif global
                </Typography>
            </Box>

            <Stack
                bgcolor={COLOR.white}
                // p={2}
                borderRadius={20}
                direction={"row"}
                gap={4}
                flexWrap={"wrap"}
                justifyContent={"space-around"}
            >
                <motion.div
                    style={{
                        width: `${window.innerWidth < 500 ? 100 : 49}%`,
                        minWidth: 280,
                    }}
                    whileInView={{
                        x: 80,
                        transition: { duration: 1 }
                    }}
                >
                    <Card
                        sx={{
                            height: 350,
                            border: "none",
                            borderRadius: 20,
                            boxShadow: `0px 5px 10px grey`,
                            transform: `translateX(-80px)`
                        }}
                    >
                        <CardCover>
                            <img src={IMAGE.agriculteurImage2} alt="" />
                        </CardCover>
                        <CardCover sx={{ background: `linear-gradient(rgba(8, 8, 41, 0),rgba(8, 8, 41, 0.5))` }}>
                            {/* <img src={IMAGE.agriculteurImage} alt="" /> */}
                        </CardCover>
                    </Card>
                </motion.div>

                <motion.div
                    style={{
                        minWidth: 300,
                        width: `${window.innerWidth < 500 ? 100 : 45}%`
                    }}
                    whileInView={{
                        x: -80,
                        transition: { duration: 1 }
                    }}
                >
                    <Box
                        sx={{
                            transform: `translateX(80px)`
                        }}
                    >
                        <Typography
                            textAlign={"justify"}
                        >
                            <Typography level='title-lg' fontWeight={700}>FIER 2  </Typography>
                            a pour objectif général de contribuer à la création de richesses et à la réduction de la
                            pauvreté pour
                            <Typography level='title-lg' fontWeight={700}> les jeunes ruraux du Mali. </Typography>
                            Son objectif de développement est de promouvoir l’esprit
                            d’entreprise
                            <Typography level='title-lg' fontWeight={700}> en milieu rural et l’intégration économique </Typography>
                            des jeunes dans les chaînes de valeur agricoles et sous-secteurs non agricoles.
                            et des microentreprises rurales, et leur augmentation durable de revenues à travers l’accès aux marchés.
                        </Typography>

                        <Box
                            mt={3}
                        >
                            <GraphModel
                                graphTilte={`Montant financement FIER 2 AGR`}
                                xAxis={["Sikasso", "Koulikoro", "Kayes", "Segou"]}
                                isGraph={true}
                                dataIsMultiLine={true}
                                height={250}
                                dataMulitiline={graphDataMultiLine}
                            />
                        </Box>
                    </Box>
                </motion.div>
            </Stack>
            {/* <img
                width={250}
                style={{
                    marginTop: -100,
                    marginBottom: -80,
                    marginRight: "-7%",
                    alignSelf: "flex-end"
                }}
                src={IMAGE.bg.background4}
            /> */}
        </Stack>
    )
}

export default ObjectifPrincipaleZone