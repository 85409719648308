import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input } from '@mui/joy'
import { TextField, MenuItem, Select, FormControl, FormLabel, Stack } from '@mui/material'
import { COLOR } from '../../../constant'
import { useEffect, useState } from 'react'
import { getAllCategorie } from '../../../function/getAllCategorie'

const Etape7 = ({
    rapportCategorie,
    setrapportCategorie,
    rapportTitle,
    setrapportTitle,
    rapportPied,
    setrapportPied,
    rapportEntete,
    setrapportEntete,
    rapportName,
    setrapportName,
    nbrLinePerPage,
    setnbrLinePerPage,
    rapportVisibility,
    setrapportVisibility,
}) => {
    const [categorieListe, setcategorieListe] = useState([]);
    const [categorieInput, setcategorieInput] = useState(null);

    const addCategorie = (value) => {
        if (!!value) {
            const isExiste = !!(categorieListe.find(valueTmp => valueTmp.nomCategorie == value));

            if (!isExiste) {
                setcategorieListe([...categorieListe, { nomCategorie: value, nomCategorie: value }])
            }

            setrapportCategorie(value);
        }
    }

    useEffect(
        () => {
            getAllCategorie().then(
                data => {
                    setcategorieListe(data);
                }
            )
        },
        []
    )



    return (
        <Stack
            gap={2}
            sx={{
                maxWidth: 450,
                minWidth: 350,
                p: 1.5
            }}
        >
            <FormControl>
                <FormLabel>Qui peut voir ce rapport ?</FormLabel>
                <Select
                    value={rapportVisibility}
                    onChange={({ target }) => setrapportVisibility(target.value)}
                >
                    <MenuItem value={`admin`}>L'administrateur</MenuItem>
                    <MenuItem value={`users`}>Tout les utilisatuers</MenuItem>
                    <MenuItem value={`public`}>Le grand public</MenuItem>
                </Select>
            </FormControl>

            <TextField
                label={`Titre du document`}
                rapportTitle={rapportTitle}
                onChange={({ target }) => setrapportTitle(target.value)}
            />

            <FormControl>
                <FormLabel>Selectionnez ou ajoutez la categorie</FormLabel>

                <Input
                    endDecorator={
                        <FontAwesomeIcon
                            color={COLOR.blue}
                            icon={faPlusCircle}
                            onClick={() => !!categorieInput && addCategorie(categorieInput)}
                        />
                    }
                    sx={{
                        mb: 1
                    }}

                    value={categorieInput}

                    placeholder='Ajoutez une nouvelle categorie'

                    onChange={({ target }) => {
                        target && setcategorieInput(target?.value);
                    }}
                />

                <Select
                    value={rapportCategorie}

                    displayEmpty

                    onChange={({ target }) => {
                        setrapportCategorie(target.value);
                    }}
                >
                    {
                        categorieListe.map((value, index) => (
                            <MenuItem
                                value={value.nomCategorie}
                                key={index}
                            >{value.nomCategorie}</MenuItem>
                        ))
                    }

                </Select>
            </FormControl>

            <TextField
                label={`En tête du document`}
                value={rapportEntete}
                onChange={({ target }) => setrapportEntete(target.value)}
            />

            <TextField
                label={`Pied de page du document`}
                value={rapportPied}
                onChange={({ target }) => setrapportPied(target.value)}
            />

            <TextField
                label={`Nom du rapport`}
                value={rapportName}
                onChange={({ target }) => setrapportName(target.value)}
            />

            <Input
                startDecorator={
                    <Button>Nombre de ligne par page ?</Button>
                }
                slotProps={{
                    input: {
                        min: 10,
                    }
                }}
                type='number'
                value={nbrLinePerPage}
                onChange={({ target }) => setnbrLinePerPage(target.value)}
            />

        </Stack>

    )
}

export default Etape7