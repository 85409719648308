import { Box, Card, CardCover, Stack, Typography } from '@mui/joy';
import { COLOR, IMAGE } from '../../constants/constants';
import { useEffect, useState } from 'react';
import { GraphModel } from '../../components/GraphModel/GraphModel';
import { motion } from "framer-motion";
import { beneficiairesFormes } from '../../functions/fiches/listeBeneficiaires/beneficiairesFormes';

const InterventionZone = () => {
    const [graphDataMultiLine, setgraphDataMultiLine] = useState([{ data: [] }]);

    useEffect(
        () => {
            beneficiairesFormes().then(
                data => {
                    setgraphDataMultiLine([{ data: [] }]);

                    let dataTmp = [
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                    ];

                    data.map(iterator => {
                        dataTmp[0][0] = dataTmp[0][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[1][0] = dataTmp[1][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[2][0] = dataTmp[2][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[3][0] = dataTmp[3][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[4][0] = dataTmp[4][0] + (iterator['Région '] == "Segou" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);

                        dataTmp[0][1] = dataTmp[0][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[1][1] = dataTmp[1][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[2][1] = dataTmp[2][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[3][1] = dataTmp[3][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[4][1] = dataTmp[4][1] + (iterator['Région '] == "Sikasso" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);

                        dataTmp[0][2] = dataTmp[0][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[1][2] = dataTmp[1][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[2][2] = dataTmp[2][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[3][2] = dataTmp[3][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[4][2] = dataTmp[4][2] + (iterator['Région '] == "Koulikoro" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);

                        dataTmp[0][3] = dataTmp[0][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "formation en entreprenariat" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[1][3] = dataTmp[1][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "Apprentissage" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[2][3] = dataTmp[2][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "formation par alternance" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[3][3] = dataTmp[3][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "formation technique" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                        dataTmp[4][3] = dataTmp[4][3] + (iterator['Région '] == "Kayes" && iterator['Type de formation '] == "Aphabetisation fonctionnelle" ? parseInt(iterator['Nombre de personnes formés']) : 0);
                    });

                    setgraphDataMultiLine(
                        [
                            {
                                data: dataTmp[0],
                                label: "Formation en entreprenaraiat"
                            },
                            {
                                data: dataTmp[1],
                                label: "Apprentissage"
                            },
                            {
                                data: dataTmp[2],
                                label: "Formation par alternance"
                            },
                            {
                                data: dataTmp[3],
                                label: "Formation technique"
                            },
                            {
                                data: dataTmp[4],
                                label: "Alphabetisation fonctionnelle"
                            }
                        ]
                    )
                }
            )
        },
        []
    )


    return (
        <Stack
            gap={2}
            bgcolor={COLOR.lightblue}
            p={3}
        >
            <Box
                width={"fit-content"}
            >
                <Typography
                    textColor={COLOR.blue}
                    level='h2'
                >
                    Zones d'interventions
                </Typography>
            </Box>

            <Stack
                // p={2}
                borderRadius={20}
                direction={"row"}
                gap={4}
                flexWrap={"wrap"}
                justifyContent={"space-around"}
            >
                <motion.div
                    style={{
                        width: "45%",
                        minWidth: 250,
                    }}
                    whileInView={{
                        x: 150,
                        transition: {
                            duration: 1
                        }
                    }}
                >
                    <Card
                        sx={{
                            border: "none",
                            background: "none",
                            minHeight: window.innerWidth < 500 ? "400px" : "500px",
                            minWidth: window.innerWidth < 500 ? "300px" : "650px",
                        }}
                        style={{ transform: "translateX(-170px)" }}
                    >
                        <CardCover>
                            <img
                                src={IMAGE.carteMali}
                                loading="lazy"
                                alt=""
                                style={{
                                    objectFit: "contain",
                                    width: "90%"
                                }}
                            />
                        </CardCover>
                    </Card>
                </motion.div>

                <motion.div
                    style={{
                        minWidth: 300,
                        width: `${window.innerWidth < 500 ? 100 : 45}%`
                    }}

                    whileInView={{
                        x: -80,
                        transition: { duration: 1 }
                    }}
                >

                    <Box
                        sx={{
                            transform: `translateX(80px)`
                        }}
                    >
                        <Typography textAlign={"justify"}>
                            FIER 2 interviendra dans
                            <Typography level="title-lg" textColor={COLOR.falseBlack}> 8 régions du Mali. </Typography>
                            En premier lieu, il interviendra dans les
                            <Typography level="title-lg" textColor={COLOR.falseBlack}> régions sud du pays. </Typography>
                            où les densités de population sont supérieures à la moyenne nationale et où
                            <Typography level="title-lg" textColor={COLOR.falseBlack}> les problèmes d’insécurité </Typography>
                            sont moins intenses, avec une focalisation sur les zones qui présentent des niveaux de pauvreté
                            élevés. Il s’agit des régions de
                            <Typography level="title-lg" textColor={COLOR.falseBlack}> Koulikoro, Sikasso, Kayes et Ségou</Typography>
                            , avec les 12 cercles retenus, des
                            villages pré-identifiés.
                        </Typography>

                        <Box
                            mt={3}
                        >
                            <GraphModel
                                graphTilte={`Beneficiaires formes par region et par secteur dactivite`}
                                xAxis={["Segou", "Sikasso", "Koulikoro", "Kayes"]}
                                isGraph={true}
                                dataIsMultiLine={true}
                                height={250}
                                dataMulitiline={graphDataMultiLine}
                            />
                        </Box>
                    </Box>
                </motion.div>

            </Stack>
        </Stack>
    )
}

export default InterventionZone