import { faInfoCircle, faPaperPlane, faRotateLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Card, CardCover, Stack, Typography } from "@mui/joy"
import { COLOR, DEMANDE_FICHE, IMAGE } from "../../constants/constants"
import { Divider } from "@mui/material"
import InputModel from "../InputModel/InputModel"
import { useContext, useEffect, useState } from "react"
import { FormNumberContext } from "../../context/FormNumberContext"
import { getClasseurFicheNom } from "../../functions/API/getClasseurFicheNom"
import { GET_CLASSEUR_FICHE_NOM_T } from "../../constants/objectTye"

const FormModel = () => {
    const [formData, setFormData] = useState({});
    const [classeurFicheData, setclasseurFicheData] = useState(null as GET_CLASSEUR_FICHE_NOM_T | null);
    const [loadingState, setloadingState] = useState(null as null | 'En cours' | 'Echouer' | 'Reussit');
    const { formNumber, setformNumber } = useContext(FormNumberContext);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setloadingState(null);
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setloadingState("En cours");
        console.group('Donnee du formulaire');
        console.table(formData);
        console.groupEnd();

        setTimeout(() => {
            setloadingState("Echouer");
        }, 3000);
    };

    useEffect(
        () => {
            if (formNumber != null) {
                const { idClasseur, idFiche } = DEMANDE_FICHE[formNumber];
                getClasseurFicheNom(idClasseur, idFiche).then(
                    data => {
                        setclasseurFicheData(data);
                    }
                );
            }
        },
        [formNumber]
    );

    useEffect(
        () => {
            switch (loadingState) {
                case "Echouer":
                    alert("Une erreur est survenue avec les API."); break;
            }
        },
        [loadingState]
    )

    return (
        formNumber != null && formNumber >= 0
            ? (
                <form
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        display: "flex",
                        top: 0,
                        left: 0,
                        background: "rgba(29, 28, 28, 0.706)",
                        backdropFilter: "blur(10px)",
                        alignItems: classeurFicheData && classeurFicheData.colonnes.length <= 4 ? "center" : "initial",
                        justifyContent: "center",
                        zIndex: 9999
                    }}

                    onSubmit={handleSubmit}
                >
                    <Stack
                        bgcolor={COLOR.lightGrey}
                        p={1}
                        borderRadius={15}
                        gap={2}
                        width={"calc(100% - 50px)"}
                        maxWidth={500}
                        minWidth={300}
                        sx={{
                            overflowY: 'scroll',
                            my: 2
                        }}
                    >
                        <Stack
                            width={30}
                            minWidth={30}
                            height={30}
                            minHeight={30}
                            bgcolor={COLOR.red}
                            justifyContent={"center"}
                            borderRadius={10}
                            color={COLOR.white}
                            alignSelf={"flex-end"}
                            onClick={() => { setformNumber(null) }}
                            zIndex={1}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Stack>

                        <Stack
                            direction={"row"}
                            gap={2}
                            mt={-5}
                        >
                            <Card
                                sx={{
                                    width: 50,
                                    minWidth: 50,
                                    height: 50,
                                    borderRadius: 0
                                }}
                            >
                                <CardCover>
                                    <img src={IMAGE.defaultImage} />
                                </CardCover>
                            </Card>
                            <Box
                                alignSelf={"flex-end"}
                            >
                                <Box>
                                    <Typography
                                        level="h4"
                                    >
                                        {`Formulaire de ${DEMANDE_FICHE[formNumber]?.nom.toLowerCase()}`}
                                    </Typography>
                                </Box>

                                <Typography gap={1} level="body-sm">
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    <Typography> Les champs dotés d'un * sont obligatoirs</Typography>
                                </Typography>
                            </Box>
                        </Stack>

                        <Divider />

                        {
                            classeurFicheData && classeurFicheData.colonnes.map(value => (
                                ![
                                    'LG',
                                    'LT',
                                    'Date_Insertion',
                                    'Login',
                                ].includes(value.nom) && <InputModel
                                    handleChange={handleChange}
                                    isRequired={true}
                                    name={value.nom}
                                    label={value.nom}
                                    defaultValue={null}
                                    placeholder={value.nom}
                                    type={'text'}
                                />
                            ))
                        }

                        <Stack
                            direction={"row"}
                            gap={1}
                        >
                            <Button
                                sx={{
                                    bgcolor: COLOR.darkGrey,
                                    gap: 1,
                                    ":hover": {
                                        bgcolor: COLOR.falseBlack
                                    }
                                }}
                                type="reset"
                            >
                                Reset
                                <FontAwesomeIcon icon={faRotateLeft} />
                            </Button>
                            <Button
                                sx={{
                                    width: "100%",
                                    gap: 1,
                                    ":hover": {
                                        bgcolor: COLOR.darKblue
                                    }
                                }}
                                loading={!!loadingState}
                                type="submit"
                            >
                                Soumettre
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            )
            : null
    )
}

export default FormModel