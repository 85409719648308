import axios from "axios";
import { RAPPORT_T } from "../constant/objectType";

export const getRapport = (idrapport: number): Promise<RAPPORT_T> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/rapport/getRapport.php`,
            {
                idRapport: idrapport
            }
        ).then(
            ({ data }: { data: RAPPORT_T }) => {
                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}