import { Button, Stack } from "@mui/material"
import StatistiqueCard from "../../components/StatistiqueCard/StatistiqueCard"
import { Input, Typography } from "@mui/joy"
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { green } from "@mui/material/colors"
import FilterZone from "./FilterZone"
import TableZone from "./TableZone"
import { useEffect, useState } from "react"
import { getAllrapports } from "../../function/getAllRapport"
import { getAllCategorie } from "../../function/getAllCategorie"

const AccueilRepporting = () => {
    const [searchValue, setsearchValue] = useState(null);
    const [nbrRapport, setnbrRapport] = useState(0);
    const [nbrCategorie, setnbrCategorie] = useState(0);
    const [listeRapport, setlisteRapport] = useState([]);
    const [rapportListFiltered, setrapportListFiltered] = useState([]);
    const [categorieListe, setcategorieListe] = useState([])

    const [categorieValue, setcategorieValue] = useState(null);
    const [isDeleteFormOpened, setisDeleteFormOpened] = useState(false);

    useEffect(
        () => {
            getAllrapports().then(
                data => {
                    setnbrRapport(data.length);
                    setlisteRapport(data);
                    setrapportListFiltered(data);
                }
            );

            getAllCategorie().then(
                data => {
                    setnbrCategorie(data.length);
                    setcategorieListe(data);
                }
            )
        },
        []
    );

    useEffect(
        () => {
            setrapportListFiltered(
                listeRapport.filter((value) => value?.nomRapport?.toLowerCase()?.includes(searchValue?.toLowerCase()))
            );
        },
        [searchValue]
    )

    useEffect(
        () => {
            if (categorieValue == null) {
                setrapportListFiltered(listeRapport);
            }
            else {
                setrapportListFiltered(
                    listeRapport.filter((value) => value?.id_categorie == categorieValue)
                )
            }
        },
        [categorieValue]
    )

    return (
        <Stack
            py={5}
            px={2}
            gap={5}
            bgcolor={"white"}
        >
            <Stack
                direction={"row"}
                gap={2}
                flexWrap={"wrap"}
            >
                <StatistiqueCard
                    name={"Nombre de Rapport"}
                    value={nbrRapport}
                />
                <StatistiqueCard
                    name={"Nombre de categorie"}
                    value={nbrCategorie}
                />
            </Stack>

            <Stack
                gap={1}
                direction={"row"}
                flexWrap={window.innerWidth < 600 ? "wrap" : "nowrap"}
            >
                <Input
                    endDecorator={
                        <FontAwesomeIcon icon={faSearch} />
                    }
                    sx={{
                        width: "100%"
                    }}
                    placeholder="Tapez le nom d'un rapport"
                    value={searchValue}
                    onChange={(e) => setsearchValue(e.target.value)}
                />
                <Button
                    sx={{
                        gap: 1,
                        textTransform: "initial",
                        bgcolor: green[900],
                        color: "white",
                        width: 250,
                        ":hover": {
                            bgcolor: green[800]
                        }
                    }}
                    href="/repporting/create-rapport"
                >
                    <Typography
                        textColor={"white"}
                    >
                        Nouveau Rapport
                    </Typography>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
            </Stack>

            <TableZone data={rapportListFiltered}  />

            <FilterZone
                categorieListe={categorieListe}
                categorieValue={categorieValue}
                setcategorieValue={setcategorieValue}
            />
        </Stack >
    )
}

export default AccueilRepporting