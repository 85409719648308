import { Avatar, Card, Stack, Typography } from '@mui/joy';
import { COLOR } from '../../constants/constants';
import { PieChart } from '@mui/x-charts';
import { useEffect, useState } from 'react';
import { financementFier2AGR } from '../../functions/fiches/listeBeneficiaires/financementFier2AGR';
import { green, orange, purple, red } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const FinancementZone = () => {
    const [investissementFier2, setinvestissementFier2] = useState([
        { label: 'Sikasso', value: 0, color: 'grey', region: 'Sikasso', },
        { label: 'Koulikoro', value: 0, color: 'grey', region: 'Koulikoro', },
        { label: 'Kayes', value: 0, color: 'grey', region: 'Kayes', },
        { label: 'Segou', value: 0, color: 'grey', region: 'Segou', },
    ]);

    const [financementFacilitation, setfinancementFacilitation] = useState([
        { label: 'Sikasso', value: 0, color: 'grey', region: "Sikasso" },
        { label: 'Koulikoro', value: 0, color: 'grey', region: "Koulikoro" },
        { label: 'Kayes', value: 0, color: 'grey', region: "Kayes" },
        { label: 'Segou', value: 0, color: 'grey', region: "Segou" },
    ]);

    const [montatInvestissementFier2, setmontatInvestissementFier2] = useState(0);
    const [montantfinancementFacilitation, setmontantfinancementFacilitation] = useState(0);

    const [dataIsLoaded, setdataIsLoaded] = useState(false);

    useEffect(
        () => {
            financementFier2AGR().then(
                data => {
                    let dataTmp = [
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                    ];

                    let montantInvestissement = 0;
                    let montantFinancement = 0;

                    data.forEach(iterator => {
                        let montant1: number = iterator['Montant de l\'investissement FIER 2'];
                        let montant2: number = iterator['Montant du financement par facilitation'];

                        dataTmp[0][0] = dataTmp[0][0] + ((iterator['Region '] == "Sikasso" || iterator['Region '] == "Sokasso") ? montant1 : 0);
                        dataTmp[1][0] = dataTmp[1][0] + ((iterator['Region '] == "Sikasso" || iterator['Region '] == "Sokasso") ? montant2 : 0);

                        dataTmp[0][1] = dataTmp[0][1] + (iterator['Region '] == "Koulikoro" ? montant1 : 0);
                        dataTmp[1][1] = dataTmp[1][1] + (iterator['Region '] == "Koulikoro" ? montant2 : 0);

                        dataTmp[0][2] = dataTmp[0][2] + (iterator['Region '] == "Kayes" ? montant1 : 0);
                        dataTmp[1][2] = dataTmp[1][2] + (iterator['Region '] == "Kayes" ? montant2 : 0);

                        dataTmp[0][3] = dataTmp[0][3] + (iterator['Region '] == "Segou" ? montant1 : 0);
                        dataTmp[1][3] = dataTmp[1][3] + (iterator['Region '] == "Segou" ? montant2 : 0);

                        montantInvestissement += montant1;
                        montantFinancement += montant2;
                    });
                    setmontantfinancementFacilitation(montantFinancement);
                    setmontatInvestissementFier2(montantInvestissement);

                    setinvestissementFier2([
                        {
                            label: 'Investissement FIER 2 dans la region de Sikasso',
                            value: dataTmp[0][0],
                            color: green[300],
                            region: "Sikasso"
                        },
                        {
                            label: 'Investissement FIER 2 dans la region de Koulikoro',
                            value: dataTmp[0][1],
                            color: purple[100],
                            region: "Koulikoro"
                        },
                        {
                            label: 'Investissement FIER 2 dans la region de Kayes',
                            value: dataTmp[0][2],
                            color: red[300],
                            region: "Kayes"
                        },
                        {
                            label: 'Investissement FIER 2 dans la region de Segou',
                            value: dataTmp[0][3],
                            color: orange[100],
                            region: "Segou"
                        },
                    ]);

                    setfinancementFacilitation([
                        {
                            label: 'Financement par facilitation dans la region de Sikasso',
                            value: dataTmp[1][0],
                            color: green[900],
                            region: "Sikasso"
                        },
                        {
                            label: 'Financement par facilitation dans la region de Koulikoro',
                            value: dataTmp[1][1],
                            color: purple[900],
                            region: "Koulikoro"
                        },
                        {
                            label: 'Financement par facilitation dans la region de Kayes',
                            value: dataTmp[1][2],
                            color: red[900],
                            region: "Kayes"
                        },
                        {
                            label: 'Financement par facilitation dans la region de Segou',
                            value: dataTmp[1][3],
                            color: orange[800],
                            region: "Segou"
                        },
                    ]);
                    setdataIsLoaded(true);
                }
            );
        },
        []
    );

    return (
        dataIsLoaded
            ? (
                <Stack
                    gap={2}
                    p={1}
                    borderRadius={8}
                    bgcolor={COLOR.white}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Stack
                        gap={2}
                        width={"97%"}
                        flexWrap={"wrap"}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}

                    >
                        <Card
                            sx={{
                                width: window.innerWidth < 500 ? "100%" : 500,
                                border: "none",
                                bgcolor: "transparent"
                            }}
                        >
                            <PieChart
                                width={window.innerWidth < 500 ? 400 : 500}
                                height={window.innerWidth > 500 ? 400 : 300}
                                series={[
                                    {
                                        data: investissementFier2,
                                        outerRadius: 115,
                                        arcLabel: (params) => window.innerWidth > 500 && (params.region ?? ''),
                                        cornerRadius: 10,
                                    },
                                    {
                                        data: financementFacilitation,
                                        innerRadius: 130,
                                        arcLabel: (params) => window.innerWidth > 500 && (params.region ?? ''),
                                        cornerRadius: 10,
                                        paddingAngle: 1
                                    },
                                ]}
                                slotProps={{
                                    legend: { hidden: true },
                                }}

                                skipAnimation={false}
                            />
                        </Card>

                        <Stack
                            gap={1}
                            justifySelf={"center"}
                        >
                            {
                                investissementFier2.map((value, index) => (
                                    <Stack
                                        title={value.label}
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: value.color
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCircle} />
                                        </Avatar>
                                        {value.label}
                                    </Stack>
                                ))
                            }
                            {
                                financementFacilitation.map((value, index) => (
                                    <Stack
                                        title={value.label}
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: value.color
                                            }}
                                        >
                                            <FontAwesomeIcon color='white' icon={faCircle} />
                                        </Avatar>
                                        {value.label}
                                    </Stack>
                                ))
                            }
                        </Stack>
                    </Stack>

                    <Stack
                        // justifyContent={"center"}
                        gap={2}
                        direction={"row"}
                        flexWrap={"wrap"}
                        sx={{
                            "& > div": {
                                bgcolor: COLOR.lightblue,
                                p: 1,
                                gap: 1,
                                width: window.innerWidth > 500 ? 280 : "100%",
                                "& :first-child": {
                                    fontSize: 17,
                                    fontWeight: 700
                                },
                            }
                        }}
                    >
                        <Stack>
                            <Typography>Montant de l'investissement FIER 2</Typography>
                            <Typography level='h1'>
                                {montatInvestissementFier2.toLocaleString()} FCFA
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography>Montant financement par facilitation</Typography>
                            <Typography level='h1'>
                                {montantfinancementFacilitation.toLocaleString()} FCFA
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )
            : undefined
    )
}

export default FinancementZone