import { getClasseurFiche } from "../../API/getClasseurFiche";

interface REQUEST_T {
    "Nombre de personne dont la résilience s'est renforcée": number,
    "Zones d'intervention": string
}

export const personneResilienceRenforcee = (): Promise<REQUEST_T[]> => {
    // cette api recupere les beneficiaires du formé
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(4, 10).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}