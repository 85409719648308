import { getClasseurFiche } from "../../API/getClasseurFiche";

/**
 * Interface for the request data
 */
interface REQUEST_T {
    /**
     * Number of rural enterprises supported reporting an increase in profits
     */
    "Nombre d'entreprises rurales appuyées signalant une hausse de leurs bénéfices": number,
    /**
     * Number of people whose income has increased
     */
    "Nombre de Personnes dont le revenu a augmenté": number,
    /**
     * Number of people whose resilience has been strengthened
     */
    "Nombre de personnes dont la résilience s’est renforcée ": number,
    /**
     * Region
     */
    "Région": string
};

/**
 * Retrieves data on rural enterprises supported reporting an increase in profits
 * 
 * @returns A promise resolving to an array of objects containing the data
 * 
 * @example
 * entrepriseSignalantUneHausseDeBenefices().then(data => console.log(data));
 * // Output:
 * // [
 * //   {
 * //     "Nombre d'entreprises rurales appuyées signalant une hausse de leurs bénéfices": 10,
 * //     "Nombre de Personnes dont le revenu a augmenté": 50,
 * //     "Nombre de personnes dont la résilience s’est renforcée ": 20,
 * //     "Région": "Region 1"
 * //   },
 * //   {
 * //     "Nombre d'entreprises rurales appuyées signalant une hausse de leurs bénéfices": 15,
 * //     "Nombre de Personnes dont le revenu a augmenté": 75,
 * //     "Nombre de personnes dont la résilience s’est renforcée ": 30,
 * //     "Région": "Region 2"
 * //   }
 * // ]
 */
export const entrepriseSignalantUneHausseDeBenefices = (): Promise<REQUEST_T[]> => {
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(7, 22).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}