import { Box, Stack, Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import ContactZone from './ContactZone'
import LiensUtilesZone from './LiensUtilesZone'
import DonneeZone from './DonneeZone'
import NewsLetterZone from './NewsLetterZone'

const Footer = () => {
    return (
        <Box
            sx={{
                mt: 8,
                background: `linear-gradient(45deg, ${COLOR.falseBlack} , ${COLOR.blue})`,
            }}
        >
            <Stack
                p={2}
                gap={2}
                direction={"row"}
                flexWrap={"wrap"}
                justifyContent={"space-around"}
                sx={{
                    "& > *": {
                        minWidth: window.innerWidth < 500 && "100%"
                    }
                }}
            >
                <ContactZone />
                <NewsLetterZone />
                <LiensUtilesZone />
                <DonneeZone />
            </Stack>
            <Box
                textAlign={"Center"}
            >
                <Typography
                    level='body-xs'
                >Powered By COSIT</Typography>
            </Box>
        </Box>
    )
}

export default Footer