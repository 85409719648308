import { faCheck, faCircleDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, Typography } from '@mui/joy'
import { Avatar } from '@mui/material'
import { blue, grey } from '@mui/material/colors'

const EtapeIndex = ({ index, checked = false, title }) => {
    return (
        <Stack
            alignItems={"center"}
            title={title}
        >
            <Avatar
                variant="square"
                sx={{
                    bgcolor: checked ? blue[300] : grey.A700
                }}
            >
                <FontAwesomeIcon
                    icon={checked ? faCheck : faCircleDot}
                />
            </Avatar>
            <Typography
                fontWeight={700}
                fontSize={11}
            >Etape {index}</Typography>
        </Stack>
    )
}

export default EtapeIndex