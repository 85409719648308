import { faSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, Switch, Typography } from "@mui/joy";
import { FormControl, FormLabel, Input, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { COLOR } from "../../../constant";

const filterOptions = [
    {
        text: "Est égale à",
        signe: "="
    },
    {
        text: "Est différent de",
        signe: "!="
    },
    {
        text: "Est supperieur de",
        signe: ">"
    },
    {
        text: "Est inferieur à",
        signe: "<"
    },
    {
        text: "Contient les caractères",
        signe: "^"
    },
    {
        text: "Ne contient pas les caractères",
        signe: "!^"
    },
    {
        text: "Se commence par",
        signe: "<&"
    },
    {
        text: "Ne se termine par",
        signe: "&>"
    },
]

const Etape3 = ({ fieldNewNameList = [], filterTable = [], setfilterTable, fildColor = "#0A480E", setfildColor, isAllTrue, setisAllTrue }) => {
    const [currentField, setcurrentField] = useState(null);
    const [currentSigne, setcurrentSigne] = useState("=");
    const [valeurFilter, setvaleurFilter] = useState(null);

    const addElement = () => {
        if (currentField && currentSigne && valeurFilter) {
            setfilterTable && setfilterTable([
                ...filterTable,
                {
                    field: currentField,
                    operation: currentSigne,
                    value: valeurFilter
                }
            ]);

            console.log('====================================');
            console.table(filterTable);
            console.log('====================================');

            setcurrentField(null);
            setcurrentSigne("=");
            setvaleurFilter(null);
        }
    }

    const deleteAnElement = (i) => {
        let data = [];

        filterTable.map((value, index) => {
            index != i && data.push(value);
        })

        setfilterTable && setfilterTable(data);
    }

    return (
        <Stack
            gap={2}
            sx={{
                maxWidth: 450,
                minWidth: 350,
                p: 1.5
            }}
        >
            <FormControl >
                <FormLabel>Selectionnez le champ à filtrer</FormLabel>
                <Select
                    // inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    sx={{
                        minWidth: 350
                    }}
                    value={currentField}
                    onChange={({ target }) => setcurrentField(target.value)}
                >
                    <MenuItem value={null}>Aucune</MenuItem>
                    {
                        fieldNewNameList.map((value, index) => (
                            <MenuItem
                                key={index}
                                value={value.editedName}
                            >
                                {value.editedName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <FormControl >
                <FormLabel>Selectionnez l'opération</FormLabel>
                <Select
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    sx={{
                        minWidth: 350
                    }}
                    value={currentSigne}
                    onChange={({ target }) => setcurrentSigne(target.value)}
                >
                    {
                        filterOptions.map((value, index) => (
                            <MenuItem
                                key={index}
                                value={value.signe}
                            >
                                {`${value.text} (${value.signe})`}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <Stack
                gap={1}
                direction={"row"}
            >
                <TextField
                    label={"Saisissez la Valeur"}
                    value={valeurFilter}
                    fullWidth
                    onChange={({ target }) => setvaleurFilter(target.value)}
                />
                <Button
                    onClick={() => addElement()}
                    disabled={!valeurFilter || !currentField}
                >Ajouter</Button>
            </Stack>

            <FormControl>
                <FormLabel>Condition de filtrage</FormLabel>

                <Stack
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Typography>Juste une condition doit être vraie</Typography>
                    <Switch
                        onChange={({ target }) => { setisAllTrue && setisAllTrue(target.checked) }}
                    />
                    <Typography>Toutes les conditions doivent être vraies</Typography>

                </Stack>

            </FormControl>

            <FormControl>
                <FormLabel>Couleur de l'element</FormLabel>
                <Input
                    type="color"
                    sx={{
                        bgcolor: fildColor,
                        border: 0,
                        borderRadius: "5px",
                        "& *0": {
                            border: 0,
                        }
                    }}
                    value={fildColor}
                    onChange={({ target }) => setfildColor && setfildColor(target.value)}
                />
            </FormControl>

            {
                filterTable.length > 0 && (
                    <Stack>
                        <Typography>Resumé</Typography>
                        <Box
                            p={1}
                            bgcolor={COLOR.blue}
                            color={COLOR.white}
                            borderRadius={5}
                        >
                            <Typography textColor={COLOR.white}>
                                Si : {` `}
                                {
                                    filterTable.map((value, index) => (
                                        <Typography>
                                            (
                                            <Typography
                                                fontWeight={700}
                                                textColor={COLOR.black}
                                            >{value.field}</Typography>
                                            {` `}
                                            {filterOptions.find(el => el.signe == value.operation).text}
                                            {` `}
                                            <Typography
                                                fontWeight={700}
                                                textColor={COLOR.black}
                                            >{value.value}</Typography>
                                            <Button
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    p: 1,
                                                    mx: 1,
                                                    bgcolor: "red",
                                                    transition: `.1s all`,
                                                    ":hover": {
                                                        bgcolor: "red",
                                                        transform: `scale(1.1)`,
                                                    }
                                                }}
                                                onClick={() => { deleteAnElement(index) }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                />
                                            </Button>
                                            )
                                            {
                                                index < filterTable.length - 1 && (
                                                    isAllTrue ? " et " : " ou "
                                                )
                                            }
                                        </Typography>
                                    ))
                                }
                            </Typography>
                            <Typography textColor={COLOR.white}>
                                Alors marqué la ligne en <FontAwesomeIcon
                                    icon={faSquare}
                                    color={fildColor}
                                />
                            </Typography>
                            <Stack>
                                <Button
                                    sx={{
                                        p: 1,
                                        bgcolor: "red",
                                        transition: `.1s all`,
                                        ":hover": {
                                            bgcolor: "red",
                                            transform: `scale(1.05)`,
                                        },
                                        mt: 6,
                                        gap: 1,
                                        alignSelf: "flex-end"
                                    }}

                                    onClick={() => setfilterTable([])}
                                >
                                    Tout supprimer
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            }

        </Stack>
    )
}

export default Etape3