import axios from "axios";
import { CATEGORIE_T } from "../constant/objectType"

export const getAllCategorie = (): Promise<CATEGORIE_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_SISE_API_URL}/categorie/getAllCategories.php`
        ).then(
            ({ data }: { data: CATEGORIE_T[] }) => {
                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}