import axios from "axios"

/**
 * Retrieves a specific fiche from a classeur.
 * 
 * @param {number} idClasseur - The ID of the classeur.
 * @param {number} idFiche - The ID of the fiche.
 * @returns {Promise<[]>} A promise that resolves with the fiche data or rejects with an error.
 * 
 * @example
 * getClasseurFiche(1, 2)
 *   .then((ficheData) => console.log(ficheData))
 *   .catch((error) => console.error(error));
 */
export const getClasseurFiche = (
    idClasseur: number, 
    idFiche: number
): Promise<[]> => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/classeurFiche?classeur=${idClasseur}&fiche=${idFiche}`
        ).then(
            ({data}) => {
                const response: [] = data.data.fiche;

                console.log('====================================');
                console.log("Information de la fiche", response);
                console.log('====================================');

                resolve(response);
            }
        ).catch(
            (e) => {
                console.log('====================================');
                console.error(e);
                console.log('====================================');
                reject(e);
            }
        )
    })
}