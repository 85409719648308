import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormLabel, Input } from '@mui/joy';

const InputModel = ({ name, placeholder, defaultValue, label, type, handleChange, isRequired=true }) => {
    return (
        <FormControl
            required={isRequired}
        >
            <FormLabel>{label}</FormLabel>
            <Input
                placeholder={placeholder}
                name={name}
                defaultValue={defaultValue}
                type={type}
                endDecorator={
                    type == "email"
                    && <FontAwesomeIcon icon={faEnvelope} />
                }
                onChange={(e) => {
                    handleChange(e);
                }}
            />
        </FormControl>
    )
}

export default InputModel