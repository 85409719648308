import { Stack, Avatar, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { USE_STATE_T } from "../../constants/objectTye";
import { nombreDEmploieCree } from "../../functions/fiches/listeBeneficiaires/nombreDEmploieCree";
import DonutModel from "../../components/DonutModel/DonutModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { blue, grey, orange, red } from "@mui/material/colors";
import { COLOR } from "../../constants/constants";

const TotalCard = ({ title, data }: { title: string, data: string | number }) => {
    return (
        <Stack>
            <Typography>{title}</Typography>
            <Typography level='h1'>
                {data}
            </Typography>
        </Stack>
    )
}

const DonutZone = ({ fonctionNumber }: { fonctionNumber: number }) => {
    const [data, setdata]:
        [
            data: [
                {
                    data: {
                        label: string,
                        value: number,
                        color: any,
                        region: string
                    }[],
                    outerRadius: number,
                    arcLabel: any,
                    cornerRadius: number,
                    paddingAngle: number
                }[],
            ] | [],
            setdata: USE_STATE_T
        ] = useState([]);

    const [totalCardList, settotalCardList]:
        [
            totalCardList: { title: string, data: string | number }[],
            settotalCardList: USE_STATE_T
        ] = useState([]);

    const [legendeListe, setlegendeListe]: [legendeListe: any[][], setlegendeListe: USE_STATE_T] = useState([]);
    const [title, settitle]: [legendeListe: string | null, setlegendeListe: USE_STATE_T] = useState(null);

    const loadData = async () => {
        switch (fonctionNumber) {
            case 12:
                // pour le nombre d'emploie creer
                let res: number[][] = [
                    [0, 0, 0, 0],
                    [0, 0, 0, 0],
                    [0, 0, 0, 0],
                    [0, 0, 0, 0],
                ];

                let resTotalListe: { title: string, data: string | number }[] = [];

                settitle("Nombre d'emploie créé par region");

                await nombreDEmploieCree().then(
                    (element) => {
                        element.forEach(value => {
                            switch (value.Région) {
                                case "Kayes":
                                    res[0][0] = value["Emploi AGR"];
                                    res[1][0] = value["Emploi MER "];
                                    res[2][0] = value["Nombre d'homme employé"];
                                    res[3][0] = value["Nombre de femme employée"];
                                    break;

                                case "Sikasso":
                                    res[0][1] = value["Emploi AGR"];
                                    res[1][1] = value["Emploi MER "];
                                    res[2][1] = value["Nombre d'homme employé"];
                                    res[3][1] = value["Nombre de femme employée"];
                                    break;

                                case "Koulikoro":
                                    res[0][2] = value["Emploi AGR"];
                                    res[1][2] = value["Emploi MER "];
                                    res[2][2] = value["Nombre d'homme employé"];
                                    res[3][2] = value["Nombre de femme employée"];
                                    break;

                                case "Segou":
                                    res[0][3] = value["Emploi AGR"];
                                    res[1][3] = value["Emploi MER "];
                                    res[2][3] = value["Nombre d'homme employé"];
                                    res[3][3] = value["Nombre de femme employée"];
                                    break;
                            }

                            resTotalListe.push(
                                {
                                    title: `Nombre total d'employé créé dans la region de ${value.Région}`,
                                    data: value["Nombre total d'emploi crée par le projet"]
                                }
                            );
                        });

                        settotalCardList(resTotalListe);
                    }
                )

                let dataTmp: any = [];
                let Outter = 170;
                let inner = 145;

                const legend = [
                    [
                        {
                            label: "Nombre d'emploi AGR a Kayes",
                            color: blue[300]
                        },
                        {
                            label: "Nombre d'emploi AGR a Sikasso",
                            color: orange[300]
                        },
                        {
                            label: "Nombre d'emploi AGR a Koulikoro",
                            color: grey[300]
                        },
                        {
                            label: "Nombre d'emploi AGR a Segou",
                            color: red[300]
                        },
                    ],
                    [
                        {
                            label: "Nombre d'emploi MER a Kayes",
                            color: blue[500]
                        },
                        {
                            label: "Nombre d'emploi MER a Sikasso",
                            color: orange[500]
                        },
                        {
                            label: "Nombre d'emploi MER a Koulikoro",
                            color: grey[500]
                        },
                        {
                            label: "Nombre d'emploi MER a Segou",
                            color: red[500]
                        },
                    ],
                    [
                        {
                            label: "Nombre d'homme employé a Kayes",
                            color: blue[800]
                        },
                        {
                            label: "Nombre d'homme employé a Sikasso",
                            color: orange[800]
                        },
                        {
                            label: "Nombre d'homme employé a Koulikoro",
                            color: grey[800]
                        },
                        {
                            label: "Nombre d'homme employé a Segou",
                            color: red[800]
                        },
                    ],
                    [
                        {
                            label: "Nombre de femme employé a Kayes",
                            color: blue[900]
                        },
                        {
                            label: "Nombre de femme employé a Sikasso",
                            color: orange[900]
                        },
                        {
                            label: "Nombre de femme employé a Koulikoro",
                            color: grey[900]
                        },
                        {
                            label: "Nombre de femme employé a Segou",
                            color: red[900]
                        },
                    ],
                ];

                setlegendeListe(legend);

                res.forEach(
                    (value, index) => {
                        Outter -= 30;
                        inner -= 30;
                        dataTmp.push(
                            {
                                data: value.map((valueTmp, indexTmp) => {
                                    let region = "";

                                    switch (indexTmp + 1) {
                                        case 1:
                                            region = "Kayes";
                                            break;
                                        case 2:
                                            region = "Sikasso";
                                            break;
                                        case 3:
                                            region = "Koulikoro";
                                            break;
                                        case 4:
                                            region = "Segou";
                                            break;
                                    }

                                    return {
                                        label: `${legend[index][indexTmp].label} dans la region de ${region}`,
                                        value: valueTmp,
                                        color: legend[index][indexTmp].color,
                                        region: region
                                    }
                                }),
                                outerRadius: Outter,
                                innerRadius: inner,
                                // arcLabel: (params: any) => window.innerWidth < 800 ? "" : params.region,
                                cornerRadius: 10,
                            }
                        )
                    }
                );

                setdata(dataTmp);
                break;
        }
    }

    useEffect(
        () => {
            loadData();
        },
        [fonctionNumber]
    )
    return (
        <Stack
            bgcolor={COLOR.white}
            gap={2}
            p={2}

        >
            <Typography level="h4">{title}</Typography>
            <Stack
                direction={"row"}
                gap={3}
                flexWrap={"wrap"}
            >
                <DonutModel data={data} />

                <Stack
                    gap={1}
                    justifySelf={"center"}
                    py={2}
                    maxHeight={300}
                    sx={{
                        overflowY: "scroll"
                    }}
                >
                    {
                        legendeListe.map((valueTmp, index) => (
                            valueTmp.map((value, index) => {
                                return (
                                    <Stack
                                        title={value?.label}
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: value?.color
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCircle} />
                                        </Avatar>
                                        {value.label}
                                    </Stack>
                                )
                            })
                        ))
                    }
                </Stack>
            </Stack>

            <Stack>
                <Stack
                    gap={2}
                    direction={"row"}
                    flexWrap={"wrap"}
                    sx={{
                        "& > div": {
                            bgcolor: COLOR.lightblue,
                            p: 1,
                            gap: 1,
                            width: window.innerWidth > 500 ? 280 : "100%",
                            "& :first-child": {
                                fontSize: 17,
                                fontWeight: 700
                            },
                        }
                    }}
                >
                    {
                        totalCardList.map((value, index) => (
                            <TotalCard
                                data={value.data}
                                title={value.title}
                            />
                        ))
                    }
                </Stack>
            </Stack>

        </Stack>
    )
}

export default DonutZone