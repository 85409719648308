import { Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import { axisClasses, BarChart, LineChart } from '@mui/x-charts'
import { Paper } from '@mui/material'
import { useEffect } from 'react'


export const GraphModel = ({
    xAxis = [],
    yAxis = [],
    graphTilte = null,
    isGraph = true,
    isShowArea = true,
    dataIsMultiLine = false,
    maxWidth,
    dataMulitiline = [{ data: [] }],
    height,
    bgColor,
    borderRadius
}) => {
    return (
        <Paper
            elevation={5}
            sx={{
                borderRadius: borderRadius || 5,
                p: 2,
                // bgcolor: COLOR.lightGrey,
                background: !!bgColor ? bgColor : `linear-gradient(45deg, ${COLOR.lightGrey}, ${COLOR.lightblue})`,
                maxWidth: maxWidth || 500,
                border: `2px solid`
            }}
        >
            {
                isGraph ?
                    !dataIsMultiLine ? (
                        <BarChart
                            xAxis={[{
                                data: xAxis,
                                scaleType: "band",
                            }]}

                            series={[{
                                data: yAxis,
                                color: COLOR.blue,
                            }]}

                            height={height || 300}
                            sx={{
                                [`& .${axisClasses.left}`]: {
                                    line: {
                                        stroke: COLOR.blue,
                                        strokeWidth: 3
                                    },
                                    text: {
                                        fill: COLOR.blue,
                                        fontWeight: 700
                                    },
                                },
                                [`& .${axisClasses.tick}`]: {
                                    display: "none"
                                },
                            }}

                        />
                    )
                        : (
                            <BarChart
                                xAxis={[{
                                    data: xAxis,
                                    scaleType: "band",
                                }]}



                                series={
                                    dataMulitiline
                                }

                                height={height || 300}
                                sx={{
                                    [`& .${axisClasses.left}`]: {
                                        line: {
                                            stroke: COLOR.blue,
                                            strokeWidth: 3
                                        },
                                        text: {
                                            fill: COLOR.blue,
                                            fontWeight: 700
                                        },
                                    },
                                    [`& .${axisClasses.tick}`]: {
                                        display: "none"
                                    },
                                }}

                            />
                        )
                    : (
                        <LineChart
                            xAxis={[{
                                data: xAxis,
                                scaleType: "band",
                            }]}

                            series={[
                                {
                                    data: yAxis,
                                    color: COLOR.blue,
                                    area: { isShowArea }
                                },
                            ]}
                            height={height || 300}
                            sx={{
                                [`& .${axisClasses.left}`]: {
                                    line: {
                                        stroke: COLOR.blue,
                                        strokeWidth: 3
                                    },
                                    text: {
                                        fill: COLOR.blue,
                                        fontWeight: 700
                                    },
                                },
                                [`& .${axisClasses.tick}`]: {
                                    display: "none"
                                },
                            }}
                        />
                    )
            }
            <Typography
                fontWeight={700}
                textAlign={"center"}
            >
                {graphTilte}
            </Typography>
        </Paper>
    )
}