import { Table } from "@mui/joy";
import { TableBody } from "@mui/material";
import { Field } from "./Field";
import { useEffect } from "react";
import { getViewColumns } from "../../../function/getViewColumns";

const Etape2 = ({ viewName, setfieldNewNameList, fieldNewNameList = [] }) => {
    useEffect(
        () => {
            viewName && (
                getViewColumns(viewName).then(
                    (data) => {
                        let res = [];

                        data.map(value => {
                            res.push({
                                orignalName: value,
                                editedName: value
                            })
                        });

                        setfieldNewNameList(res);
                    }
                )
            )
        },
        [viewName]
    );

    return (
        <Table
            sx={{
                maxWidth: 500,
                minWidth: 350,
            }}
        >
            <TableBody>
                {
                    fieldNewNameList.map((value, index) => (
                        <Field
                            name={value.orignalName}
                            index={index}
                            setfieldNewNameList={setfieldNewNameList}
                            fieldNewNameList={fieldNewNameList}
                        />
                    ))
                }
            </TableBody>
        </Table>
    )
}

export default Etape2