import axios from "axios"
import { GET_ALL_BENEFICIAIRES_T } from "../../constants/objectTye";

/**
 * Retrieves a list of all beneficiaires from the API.
 * 
 * @returns A promise resolving to an array of beneficiaire request objects.
 * 
 * @example
 * getAllBeneficiaires()
 *   .then((beneficiaires) => {
 *     console.log(beneficiaires);
 *   })
 *   .catch((error) => {
 *     console.error(error);
 *   });
 */
export const getAllBeneficiaires = () : Promise<GET_ALL_BENEFICIAIRES_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/identification`
        ).then(
            ({data}) => {
                const response : GET_ALL_BENEFICIAIRES_T[] = data.data;

                console.log('====================================');
                console.log("Liste des beneficiaires", response);
                console.log('====================================');

                resolve(response);
            }
        ).catch(
            (e)=>{
                console.log('====================================');
                console.error(e);
                console.log('====================================');
                reject(e);
            }
        )
    })
}