import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Button, ButtonGroup, Stack, Typography } from "@mui/joy";
import { COLOR, LOCATION } from "../../constants/constants";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowAltCircleUp, faHashtag } from "@fortawesome/free-solid-svg-icons";

const location = LOCATION.bamako;
const contain = (<>Capitale du Mali.</>);

const cartographiOption = [
    {
        index: 1,
        nom: "Beneficiaire individuel",
        icon: faHashtag
    },
    {
        index: 2,
        nom: "Infracstructures créés ou remis en état",
        icon: faHashtag
    },
]

const Cartographie = ({ element = [{ location: location, content: contain }], setfiltreOptionIndex }) => {
    const [userlocation, setuserlocation] = useState(LOCATION.bamako);
    const [isshowFilterOption, setisshowFilterOption] = useState(false);

    useEffect(
        () => {
            navigator.geolocation.getCurrentPosition((position) => {
                let lat = position.coords.latitude;
                let long = position.coords.longitude;
                setuserlocation([lat, long]);
            });
        },
        []
    )

    return (
        <Stack
            height={500}
            bgcolor={COLOR.white}
            borderRadius={10}
            direction={"row"}
            p={1}
        >
            <Stack
                gap={2}
                width={"100%"}
            >
                <Typography level="h4">
                    La Cartographie des indicateurs
                </Typography>

                <MapContainer
                    center={location}
                    zoom={7}
                    scrollWheelZoom={false}
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 5,
                        overflow: "hidden"
                    }}
                >
                    <TileLayer
                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                    />
                    {
                        element.map((value, index) => (
                            <Marker
                                position={value.location}
                                key={index}
                                s
                            >
                                <Popup>
                                    {value.content}
                                </Popup>
                            </Marker>
                        ))
                    }
                    <Marker
                        position={userlocation}
                    >
                        <Popup>
                            "Votre position"
                        </Popup>
                    </Marker>
                </MapContainer>
            </Stack>

            <Stack
                position={"absolute"}
                gap={1}
                zIndex={9000}
                alignSelf="flex-end"
                bgcolor={COLOR.white}
                p={0.2}
                borderRadius={10}
            >
                {
                    isshowFilterOption && (
                        <ButtonGroup
                            orientation="vertical"
                            variant="solid"
                        >
                            {
                                cartographiOption.map((value, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => {
                                            setfiltreOptionIndex && setfiltreOptionIndex(value.index);
                                            setisshowFilterOption(false);
                                        }}
                                    >{value.nom}</Button>
                                ))
                            }
                        </ButtonGroup>
                    )
                }
                <Button
                    endDecorator={
                        <FontAwesomeIcon icon={isshowFilterOption ? faArrowAltCircleDown : faArrowAltCircleUp} />
                    }
                    onClick={() => setisshowFilterOption(!isshowFilterOption)}
                >
                    Filtre d'indicateur
                </Button>
            </Stack>
        </Stack>
    )
}

export default Cartographie