import { Box, Stack, Typography } from "@mui/joy";
import { COLOR } from "../../constants/constants";
import { Divider } from "@mui/material";

export const InfracstructureCard = ({ nom, dateDebutDesTravaux, dateMiseEnService, nbrBeneficiaire, partenaireEnCharge, superficie = 0, typeInfracstructure }) => {
    return (
        <Stack
            gap={2}
            bgcolor={COLOR.white}
            borderRadius={15}
            width={220}
        >
            <Stack>
                <Box
                    sx={{
                        "& *": {
                            fontSize: 11,

                        },
                        "& span": {
                            my: -10
                        },
                        "& :last-child:is(hr)": {
                            my: 1
                        },
                        "& > span :first-child": {
                            fontWeight: 700
                        },
                    }}
                >
                    {
                        nom && (
                            <Stack>
                                <Typography fontWeight={700}>{nom} </Typography>
                                {/* <Typography>{nom}</Typography> */}
                                <Divider />
                            </Stack>
                        )
                    }
                    {
                        typeInfracstructure && (
                            <Stack>
                                <Typography fontWeight={700}>Types d'infrastructures:</Typography>
                                <Typography>{typeInfracstructure}</Typography>
                                <Divider />
                            </Stack>
                        )
                    }
                    {
                        partenaireEnCharge && (
                            <Stack>
                                <Typography fontWeight={700}>Partenaires en charges de la construction: </Typography>
                                <Typography>{partenaireEnCharge}</Typography>
                                <Divider />
                            </Stack>
                        )
                    }
                    {
                        dateDebutDesTravaux && (
                            <Stack>
                                <Typography fontWeight={700}>Date de début des travaux:</Typography>
                                <Typography>{dateDebutDesTravaux}</Typography>
                                <Divider />
                            </Stack>
                        )
                    }
                    {
                        dateMiseEnService && (
                            <Stack>
                                <Typography fontWeight={700}>date de mise en service: </Typography>
                                <Typography>{dateMiseEnService}</Typography>
                                <Divider />
                            </Stack>
                        )
                    }
                    {
                        nbrBeneficiaire && (
                            <Stack>
                                <Typography fontWeight={700}>Nombre de bénéficiairs de l'infrastructure: </Typography>
                                <Typography>{nbrBeneficiaire}</Typography>
                                <Divider />
                            </Stack>
                        )
                    }
                    {
                        (!!superficie && superficie > 0) && (
                            <Stack>
                                <Typography fontWeight={700}>Superficie de l'infrastructure: </Typography>
                                <Typography>{superficie?.toLocaleString()} Hectare</Typography>
                            </Stack>
                        )
                    }
                </Box>
            </Stack>
        </Stack>
    )
}
