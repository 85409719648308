import { Stack } from "@mui/joy";
import FicheListe from "./FicheListe";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FinancementZone from "./FinancementZone";
import CartographieZone from "./CartographieZone";
import StatistiqueDataZone from "./StatistiqueDataZone";
import NombreDEmploieCreeZone from "./NombreDEmploieCreeZone";

const Statistique = () => {
    const { functionNumber } = useParams();

    const [fonctionNumber, setfonctionNumber] = useState(4);

    useEffect(
        () => {
            setfonctionNumber(parseInt(functionNumber) || 4);
        },
        [functionNumber]
    )

    return (
        <Stack
            gap={5}
        >
            <CartographieZone />

            <FinancementZone />

            <NombreDEmploieCreeZone />

            <Stack
                direction={"row"}
                gap={2}
                flexWrap={window.innerWidth < 900 && "wrap"}
            >
                <FicheListe setfonctionNumber={setfonctionNumber} />

                <StatistiqueDataZone
                    fonctionNumber={fonctionNumber}
                />

            </Stack>

        </Stack>
    )
}

export default Statistique