import axios from "axios";
import { GET_CLASSEUR_FICHE_NOM_T } from "../../constants/objectTye";

export const getClasseurFicheNom = async (idClasseur: number, idFiche: number) : Promise<GET_CLASSEUR_FICHE_NOM_T | null> => {
    try {
        const { data }:
            {
                data : any
            } = await axios.get(`${process.env.REACT_APP_API_URL}/classeurFicheNom?classeur=${idClasseur}&fiche=${idFiche}`);

        return data?.data;

    } catch (error) {
        console.log('====================================');
        console.error('Une erreur est survenue', error);
        console.log('====================================');
        return null;
    }
}