import { Button, ButtonGroup, Typography } from "@mui/joy";
import { COLOR, LISTE_DES_FICHES } from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const FicheListe = ({ setfonctionNumber }) => {
    const [keySelected, setkeySelected] = useState(null);

    return (
        <ButtonGroup
            orientation="vertical"
            variant="soft"
            sx={{
                overflowY: "scroll",
                maxWidth: window.innerWidth > 1000 ? "30%" : "100%",
                maxHeight: 500,
                height: "fit-content",
            }}
        >
            {
                LISTE_DES_FICHES.map((value, index) => (
                    <Button
                        title={value.text}
                        onClick={() => {
                            setfonctionNumber(value.fonctionNumber);
                            setkeySelected(index);
                        }}
                        endDecorator={
                            <FontAwesomeIcon color={index == keySelected ? COLOR.white : COLOR.darKblue} icon={faArrowAltCircleRight} />
                        }
                        sx={{
                            bgcolor: index == keySelected ? COLOR.blue : null,
                            ":hover": {
                                bgcolor: index == keySelected ? COLOR.blue : null,
                            }
                        }}
                    >
                        <Typography
                            textOverflow={"ellipsis"}
                            sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: "100%"
                            }}
                            textColor={index == keySelected ? COLOR.white : COLOR.black}
                            textAlign={"left"}
                            fontSize={10}
                        >
                            {value.text}
                        </Typography>
                    </Button>
                ))
            }
        </ButtonGroup>
    )
}

export default FicheListe