import { Route, Routes } from 'react-router-dom'
import Accueil from '../../Pages/Accueil'
import Statistique from '../../Pages/Statistique'
import AccueilRepporting from '../../Sise/src/Pages/Accueil'
import CreateRapport from '../../Sise/src/Pages/create-rapport'
import PrintRapport from '../../Sise/src/Pages/PrintRapport/PrintRapport'

const Router = () => {
  return (
    <Routes >
      <Route path="/" element={<Accueil />} />
      <Route path="/statistique/" element={<Statistique />} />
      <Route path="/statistique/:functionNumber" element={<Statistique />} />
      <Route path="repporting/*" element={<AccueilRepporting />} />
      <Route path="repporting/accueil" element={<AccueilRepporting />} />
      <Route path="repporting/create-rapport" element={<CreateRapport />} />
      <Route path="repporting/print-rapport/:idRapport" element={<PrintRapport />} />

      {/* pour show the sise plateforme in iframe instad the stat page */}
      {/* <Route path="/statistique" element={<Sise/>} /> */}
    </Routes>
  )
}

export default Router