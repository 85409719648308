import { useEffect, useState } from 'react';
import { getAllBeneficiaires } from '../../functions/API/getAllBeneficiaires';
import { BeneficiaireCard } from '../../components/BeneficiaireCard/BeneficiaireCard';
import Cartographie from '../../components/Cartographie/Cartographie';
import { USE_STATE_T } from '../../constants/objectTye';
import { identificationInfracstructuresConstruitesOuRemisEnEtatParFier2 } from '../../functions/fiches/listeBeneficiaires/identificationInfracstructuresConstruitesOuRemisEnEtatParFier2';
import { InfracstructureCard } from '../../components/InfracstructureCard/InfracstructureCard';

const CartographieZone = () => {
    const [cartographieChild, setcartographieChild]:
        [
            cartographieChild: any[],
            setcartographieChild: USE_STATE_T
        ] = useState([]);

    const [filtreOptionIndex, setfiltreOptionIndex]:
        [
            cartographieChild: number,
            setcartographieChild: USE_STATE_T
        ] = useState(1);

    /*
    *If the request is loading, the value would be "loading". Default
    *If the request is a success, the value would be "loaded"
    *If the request is a success but any value have location value, the value would be "noValue"
    *If the request is an error, the value would be "notLoaded"
    */

    const loadCartographieChild = () => {
        let res: any[] = [];
        setcartographieChild([]);

        switch (filtreOptionIndex) {
            case 1:
                getAllBeneficiaires().then(
                    (data) => {
                        data.forEach((value, index) => {
                            ((value?.LT || value?.Latitude) && (value?.LG || value?.Longitude)) && res.push(
                                {
                                    location: [value.LT || value.Latitude, value.LG || value.Longitude],
                                    content: (
                                        <BeneficiaireCard
                                            nom={value["Prenoms et nom"]}
                                            CNI={value["Numero CNI"]}
                                            dateNaissance={value["Date de naissance"]}
                                            etude={value["Niveau d etude"]}
                                            location={`${value.Commune} - ${value.Localite}`}
                                            organisation={value["Nom de l organisation"]}
                                            projet={value["Idee de projet"]}
                                            tel={value.Telephone}
                                            sexe={value.Sexe}
                                            key={index}
                                            id={value.Id}
                                            photo={value.photo}
                                        />
                                    ),
                                    data: value
                                }
                            )
                        });

                        setcartographieChild(res);
                    }
                );
                break;

            case 2:
                identificationInfracstructuresConstruitesOuRemisEnEtatParFier2().then(
                    (data) => {
                        data.forEach((value, index) => {
                            (!!(value?.LT) && !!(value?.LG)) && res.push(
                                {
                                    location: [value.LT, value.LG],
                                    content: (
                                        <InfracstructureCard
                                            dateDebutDesTravaux={value['Date de debut construction ou de réhabilitation']}
                                            dateMiseEnService={value['Date de mise en service de l\'infrastructure ']}
                                            nbrBeneficiaire={value['Nombre de personnes bénéficiant de l\'infrastructure ']}
                                            nom={value['Nom de l\'infrastructure']}
                                            partenaireEnCharge={value['Partenaires en charges de la construction ou de la rehabilitation']}
                                            typeInfracstructure={value['Types d\'infrastructures ']}
                                            key={index}
                                            superficie={value['Superficie de l\'infrastructure ha ']}
                                        />
                                    ),
                                    data: value
                                }
                            )
                        });

                        setcartographieChild(res);
                    }
                )
        }

    }

    useEffect(
        () => {
            loadCartographieChild();
        },
        [filtreOptionIndex]
    )

    return (
        <Cartographie
            element={cartographieChild}
            setfiltreOptionIndex={setfiltreOptionIndex}
        />
    )
}

export default CartographieZone