import { Box, Stack, Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import { motion } from "framer-motion"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { CircularProgress } from '@mui/material'

const StatistiqueCard = ({ title, value, color, textSize, icon, width }) => {
    return (
        <motion.div
            whileHover={{ scale: 1.01 }}
            style={{
                width: width || 290,
                minWidth: 180
            }}
        >
            <Stack
                sx={{
                    background: color || COLOR.white,
                }}
                width={width || 235}
                alignItems={"center"}
                p={2}
                maxWidth={'calc(100% - 30px)'}
            >
                <Box
                    alignSelf={"flex-end"}
                    fontSize={30}
                >
                    <FontAwesomeIcon
                        icon={icon || faChartBar} color={COLOR.blue}
                    />
                </Box>
                <Typography
                    level="title-lg"
                    textColor={COLOR.blue}
                    paddingLeft={1}
                    paddingRight={1}
                    fontSize={25}
                >
                    {value || <CircularProgress/>}
                </Typography>
                <Box
                    title={title}
                    width={"100%"}
                >
                    <Typography
                        textAlign={"center"}
                        textColor={COLOR.blue}
                        fontSize={textSize || 12}
                        fontWeight={700}
                        textOverflow={"ellipsis"}
                        sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
            </Stack>
        </motion.div>
    )
}

export default StatistiqueCard