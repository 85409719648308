import logo from "../res/images/logo.jpeg"
import agriculteurImage from "../res/images/agriculteur-image.jpg"
import agriculteurImage2 from "../res/images/agriculteur-image-2.jpg"
import agriculteurImage3 from "../res/images/agriculteur-image-3.jpg"
import carteMali from "../res/images/mali-carte.png"
import bg1 from "../res/images/bg/bg1.png"
import bg2 from "../res/images/bg/bg2.svg"
import bg3 from "../res/images/bg/bg3.png"
import bg4 from "../res/images/bg/bg4.png"
import { faAreaChart, faComment, faFileDownload, faHomeAlt, faUserAstronaut } from "@fortawesome/free-solid-svg-icons"
import { blue } from "@mui/material/colors"

export const IMAGE = {
    defaultImage: logo,
    agriculteurImage: agriculteurImage,
    agriculteurImage2: agriculteurImage2,
    agriculteurImage3: agriculteurImage3,
    carteMali: carteMali,
    bg: {
        background1: bg1,
        background2: bg2,
        background3: bg3,
        background4: bg4,
    }
}

export const COLOR = {
    white: "#FFFFFF",
    black: "#000000",
    falseBlack: "#3c3744",
    darkGrey: "#595959",
    lightGrey: "#dce0d9",
    green: "#5cb85c",
    red: "#ff3b30",
    blue: blue[900],
    darKblue: "#090c9b",
    lightblue: "#b4c5e4",
    orange: "orange",
}

export const PAGE_LINK = [
    {
        text: "Acceuil",
        link: "/",
        icon: faHomeAlt
    },
    {
        text: "statistique",
        link: "/statistique",
        icon: faAreaChart
    },
    // {
    //     text: "Repporting",
    //     link: "/repporting",
    //     icon: faChartBar
    // },
    {
        text: "Connexion",
        link: "https://fier2.fidamali.org/fiches_dynamiques.php",
        icon: faUserAstronaut
    }
]

export const CONTACT = {
    tel: [
        "+223 xx xx xx xx",
    ],
    email: [
        "contact@fier2.com",
    ],
    facebook: null,
    twitter: null
}

export const LISTE_DES_FICHES: {
    text: string,
    fonctionNumber: number,
    element: "tableau" | "graphe" | "donut" | "courbe"
}[] = [
        {
            text: "Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état",
            fonctionNumber: 1,
            element: "tableau"
        },
        {
            text: "Nombres de prestataires de services financiers soutenus dans la mise en place de stratégies de desserte, et de services et produits financiers dans les zones rurales",
            fonctionNumber: 2,
            element: "graphe"
        },
        {
            text: "Entreprises rurales appuyées signalant une hausse de leurs bénéfices",
            fonctionNumber: 3,
            element: "tableau"
        },
        {
            text: "Bénéficiaires formés",
            fonctionNumber: 4,
            element: "graphe"
        },
        {
            text: "Nombre de Personnes ayant accès à des services financiers dans les zones rurales",
            fonctionNumber: 5,
            element: "tableau"
        },
        {
            text: "Nombre de beneficiaires par secteur d'activité",
            fonctionNumber: 6,
            element: "courbe"
        },
        {
            text: "Montant financement Fier2 AGR",
            fonctionNumber: 8,
            element: "graphe"
        },
        {
            text: "Nombre de personnes dont la résilience s’est renforcée selon les zones d'interventions",
            fonctionNumber: 10,
            element: 'graphe'
        },
        // {
        //     text: "Nombre d'emploie créé",
        //     fonctionNumber: 12,
        //     element: "donut"
        // },
    ];

export const LOCATION = {
    bamako: [12.65, -8],
    kaye: [14.436788, -11.445272],
    segou: [13.4316597, -6.2482149],
    koulikoro: [12.866205, -7.559932],
    sikasso: [11.322383, -5.698398],
}

export const DEMANDE_FICHE = [
    {
        nom: 'Soumission de demandes',
        hoverTitle: "Cliquez ici pour effectuer une demande d'adhésion au programme.",
        idClasseur: 2,
        idFiche: 3,
        icon: faFileDownload
    },
    {
        nom: 'Soumission de plainte',
        hoverTitle: "Cliquez ici pour effectuer une demande de plainte.",
        idClasseur: 2,
        idFiche: 14,
        icon: faComment
    },
] 