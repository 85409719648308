import { Avatar, Box, Card, CardCover, Stack, Typography } from '@mui/joy';
import { COLOR, IMAGE } from '../../constants/constants';
import { Divider } from '@mui/material';
import { motion } from "framer-motion"

const ObjectifSpecifiqueZone = () => {
    return (
        <Stack
            gap={2}
            bgcolor={COLOR.lightblue}
            p={3}
        >
            <Box
                width={"fit-content"}
                p={1}
            >
                <Typography
                    textColor={COLOR.blue}
                    level='h2'
                >
                    Objectifs spécifiques
                </Typography>
            </Box>

            <Stack
                // bgcolor={COLOR.white}
                p={2}
                borderRadius={20}
                direction={"row"}
                gap={4}
                flexWrap={"wrap"}
                justifyContent={"space-around"}
            >
                <Box
                    minWidth={300}
                    width={`${window.innerWidth < 500 ? 100 : 45}%`}
                >
                    <Stack
                        gap={2}
                        divider={<Divider orientation="horizontal" color={COLOR.darkGrey} />}
                    >
                        <Stack
                            direction={"row"}
                            gap={1}
                        >
                            <Avatar
                                sx={{ width: 30, height: 30, bgcolor: COLOR.blue, color: COLOR.white }}
                            >1</Avatar>
                            <Typography variant="body2">
                                Rendre l’environnement institutionnel et socio-économique
                                favorable à la promotion de l’entrepreneuriat et de l’emploi des jeunes ruraux
                            </Typography>
                        </Stack>

                        <Stack
                            direction={"row"}
                            gap={1}
                        >
                            <Avatar
                                sx={{ width: 30, height: 30, bgcolor: COLOR.blue, color: COLOR.white }}
                            >2</Avatar>
                            <Typography variant="body2">
                                Renforcer les capacités institutionnelles publiques et
                                privées pour la formation et l’insertion économique des jeunes ruraux
                            </Typography>
                        </Stack>

                        <Stack
                            direction={"row"}
                            gap={1}
                        >
                            <Avatar
                                sx={{ width: 30, height: 30, bgcolor: COLOR.blue, color: COLOR.white }}
                            >3</Avatar>
                            <Typography variant="body2">
                                Réalisation d’infrastructures économiques agrégatives
                                résilientes pour les jeunes mises en exploitation et gérées de façon durable
                            </Typography>
                        </Stack>

                        <Stack
                            direction={"row"}
                            gap={1}
                        >
                            <Avatar
                                sx={{ width: 30, height: 30, bgcolor: COLOR.blue, color: COLOR.white }}
                            >4</Avatar>
                            <Typography variant="body2">
                                Appuyer les jeunes ruraux à générer des revenus décents par un accès
                                durable à des services financiers et non financiers adaptés à leurs besoins et aux
                                exigences du marché
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>

                <motion.div
                    style={{
                        width: `${window.innerWidth < 500 ? 100 : 49}%`,
                        minWidth: 280,
                    }}
                    whileInView={{
                        x: -80,
                        transition: { duration: 1 }
                    }}
                >
                    <Card
                        sx={{
                            height: 350,
                            border: "none",
                            borderRadius: 20,
                            boxShadow: `0px 5px 10px grey`,
                            transform: `translateX(80px)`
                        }}
                    >
                        <CardCover>
                            <img src={IMAGE.agriculteurImage3} alt="" />
                        </CardCover>
                        <CardCover sx={{ background: `linear-gradient(rgba(8, 8, 41, 0),rgba(8, 8, 41, 0.5))` }}>
                            {/* <img src={IMAGE.agriculteurImage} alt="" /> */}
                        </CardCover>
                    </Card>
                </motion.div>

            </Stack>
            {/* <img
                width={250}
                style={{
                    marginTop: -100,
                    marginBottom: -150,
                    marginLeft: "-7%",
                    // alignSelf: "flex-end"
                }}
                src={IMAGE.bg.background1}
            /> */}
        </Stack>
    )
}

export default ObjectifSpecifiqueZone