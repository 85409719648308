import { Stack, Avatar, Typography, Card, CardContent, Button } from "@mui/joy";
import { useEffect, useState } from "react";
import { USE_STATE_T } from "../../constants/objectTye";
import { nombreDEmploieCree } from "../../functions/fiches/listeBeneficiaires/nombreDEmploieCree";
import DonutModel from "../../components/DonutModel/DonutModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { blue, grey, orange, red } from "@mui/material/colors";
import { COLOR } from "../../constants/constants";

const legend = [
    [
        {
            label: "Emploi AGR a Kayes",
            color: blue[300]
        },
        {
            label: "Emploi AGR a Sikasso",
            color: orange[300]
        },
        {
            label: "Emploi AGR a Koulikoro",
            color: grey[300]
        },
        {
            label: "Emploi AGR a Segou",
            color: red[300]
        },
    ],
    [
        {
            label: "Emploi MER à Kayes",
            color: blue[500]
        },
        {
            label: "Emploi MER à Sikasso",
            color: orange[500]
        },
        {
            label: "Emploi MER à Koulikoro",
            color: grey[500]
        },
        {
            label: "Emploi MER à Segou",
            color: red[500]
        },
    ],
    [
        {
            label: "Homme employé à Kayes",
            color: blue[700]
        },
        {
            label: "Homme employé à Sikasso",
            color: orange[700]
        },
        {
            label: "Homme employé à Koulikoro",
            color: grey[700]
        },
        {
            label: "Homme employé à Segou",
            color: red[700]
        },
    ],
    [
        {
            label: "Femme employé à Kayes",
            color: blue[900]
        },
        {
            label: "Femme employé à Sikasso",
            color: orange[900]
        },
        {
            label: "Femme employé à Koulikoro",
            color: grey[900]
        },
        {
            label: "Femme employé à Segou",
            color: red[900]
        },
    ],
];

const TotalCard = ({ title, data }: { title: string, data: string | number }) => {
    return (
        <Stack
            sx={{
                bgcolor: COLOR.lightblue,
                p: 1,
                gap: 1,
                width: window.innerWidth > 500 ? 250 : "100%",
                "& :first-child": {
                    fontSize: 17,
                    fontWeight: 700
                },
                borderRadius: 8
            }}
        >
            <Typography>{title}</Typography>
            <Typography level='h2'>
                {data}
            </Typography>
        </Stack>
    )
}

const NombreDEmploieCreeZone = () => {
    const [data, setdata]:
        [
            data: [
                {
                    data: {
                        label: string,
                        value: number,
                        color: any,
                        region: string
                    }[],
                    outerRadius: number,
                    arcLabel: any,
                    cornerRadius: number,
                    paddingAngle: number
                }[],
            ] | [],
            setdata: USE_STATE_T
        ] = useState([]);

    const [totalCardList, settotalCardList]:
        [
            totalCardList: { title: string, data: string | number }[],
            settotalCardList: USE_STATE_T
        ] = useState([]);

    const [legendeListe, setlegendeListe]: [legendeListe: any[][], setlegendeListe: USE_STATE_T] = useState([]);
    const [title, settitle]: [legendeListe: string | null, setlegendeListe: USE_STATE_T] = useState(null);
    const [showDescriptionZone, setshowDescriptionZone]: [legendeListe: boolean, setlegendeListe: USE_STATE_T] = useState(window.innerWidth > 900);

    const loadData = async () => {
        let res: number[][] = [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ];

        let resTotalListe: { title: string, data: string | number }[] = [];

        settitle("Nombre d'emploie créé par region");

        await nombreDEmploieCree().then(
            (element) => {
                element.forEach(value => {
                    switch (value.Région) {
                        case "Kayes":
                            res[0][0] = value["Emploi AGR"];
                            res[1][0] = value["Emploi MER "];
                            res[2][0] = value["Nombre d'homme employé"];
                            res[3][0] = value["Nombre de femme employée"];
                            break;

                        case "Sikasso":
                            res[0][1] = value["Emploi AGR"];
                            res[1][1] = value["Emploi MER "];
                            res[2][1] = value["Nombre d'homme employé"];
                            res[3][1] = value["Nombre de femme employée"];
                            break;

                        case "Koulikoro":
                            res[0][2] = value["Emploi AGR"];
                            res[1][2] = value["Emploi MER "];
                            res[2][2] = value["Nombre d'homme employé"];
                            res[3][2] = value["Nombre de femme employée"];
                            break;

                        case "Segou":
                            res[0][3] = value["Emploi AGR"];
                            res[1][3] = value["Emploi MER "];
                            res[2][3] = value["Nombre d'homme employé"];
                            res[3][3] = value["Nombre de femme employée"];
                            break;
                    }

                    resTotalListe.push(
                        {
                            title: `Nombre total d'employé créé dans la region de ${value.Région}`,
                            data: value["Nombre total d'emploi crée par le projet"]
                        }
                    );
                });

                settotalCardList(resTotalListe);
            }
        )

        let dataTmp: any = [];
        let Outter = 170;
        let inner = 145;


        setlegendeListe(legend);

        res.forEach(
            (value, index) => {
                Outter -= 30;
                inner -= 30;
                dataTmp.push(
                    {
                        data: value.map((valueTmp, indexTmp) => {
                            let region = "";

                            switch (indexTmp + 1) {
                                case 1:
                                    region = "Kayes";
                                    break;
                                case 2:
                                    region = "Sikasso";
                                    break;
                                case 3:
                                    region = "Koulikoro";
                                    break;
                                case 4:
                                    region = "Segou";
                                    break;
                            }

                            return {
                                label: `${legend[index][indexTmp].label}`,
                                value: valueTmp,
                                color: legend[index][indexTmp].color,
                                region: region
                            }
                        }),
                        outerRadius: Outter,
                        innerRadius: inner,
                        arcLabel: (params: { value: number }) => params.value.toLocaleString(),
                        cornerRadius: 10,
                    }
                )
            }
        );

        setdata(dataTmp);

    }

    useEffect(
        () => {
            loadData();
        },
        []
    )
    return (
        <Stack
            bgcolor={COLOR.white}
            gap={2}
            p={2}
            borderRadius={10}
        >
            <Typography level="h4">{title}</Typography>
            <Stack
                direction={"row"}
                gap={3}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <DonutModel data={data} />

                <Stack
                    gap={1}
                    justifySelf={"center"}
                    py={2}
                    width={300}
                    maxHeight={300}
                    sx={{
                        overflowY: "scroll",
                        "::-webkit-scrollbar": {
                            display: "initial"
                        }
                    }}
                >
                    {
                        legendeListe.map((valueTmp, index) => (
                            valueTmp.map((value, index) => {
                                return (
                                    <Stack
                                        title={value?.label}
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: value?.color
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCircle} />
                                        </Avatar>
                                        {value.label}
                                    </Stack>
                                )
                            })
                        ))
                    }
                </Stack>

                <Card
                    sx={{
                        maxWidth: 400,
                        borderRadius: 10,
                        p: 1
                    }}
                >
                    <CardContent>
                        <Button
                            endDecorator={
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            }
                            onClick={() => {
                                if (window.innerWidth < 900) {
                                    setshowDescriptionZone(!showDescriptionZone);
                                }
                            }}
                        >Comprendre le graphique </Button>
                        {
                            showDescriptionZone && (
                                <Stack
                                    gap={1}
                                >
                                    <Typography>
                                        Ce graphique circulaire à quatre niveaux présente
                                        la répartition du nombre d'emploie créé dans les différentes régions par le
                                        projet FIER 2 de manière hiérarchisée.
                                    </Typography>

                                    <Stack>
                                        <Typography
                                            color="primary"
                                            fontWeight={700}
                                        >
                                            Niveau central (le plus petit cercle) :
                                        </Typography>
                                        <Typography>
                                            Nombre de femmes employées.
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <Typography
                                            color="primary"
                                            fontWeight={700}
                                        >
                                            Deuxième niveau :
                                        </Typography>
                                        <Typography>
                                            Nombre d'hommes employés.
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <Typography
                                            color="primary"
                                            fontWeight={700}
                                        >
                                            Troisième niveau :
                                        </Typography>
                                        <Typography>
                                            Nombre d'emplois MER.
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <Typography
                                            color="primary"
                                            fontWeight={700}
                                        >
                                            Troisième niveau :
                                        </Typography>
                                        <Typography>
                                            Nombre d'emplois dans le secteur agricole (AGR).
                                        </Typography>
                                    </Stack>

                                </Stack>
                            )
                        }
                    </CardContent>
                </Card>
            </Stack>

            <Stack
                direction={"row"}
            >
                <Stack
                    gap={2}
                    direction={"row"}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                    width={"100%"}
                >
                    {
                        totalCardList.map((value, index) => (
                            <TotalCard
                                data={`${value.data?.toLocaleString()} Personnes`}
                                title={value.title}
                            />
                        ))
                    }
                </Stack>
            </Stack>

        </Stack>
    )
}

export default NombreDEmploieCreeZone