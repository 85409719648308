import { Stack, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Box, Button, ButtonGroup, Table } from "@mui/joy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { grey } from "@mui/material/colors"
import { RAPPORT_T } from "../../constant/objectType"
import { deleteRapport } from "../../function/deleteRapport"

const TableZone = (
    { data }:
        {
            data: RAPPORT_T[] | null | undefined,
        }
) => {
    const handleDelete = (idRapport: number) => {
        if (window.confirm("Etes vous sur de vouloir supprimer cet rapport ?")) {
            deleteRapport(idRapport).then(
                () => {
                    window.location.reload();
                }
            ).catch(
                () => {
                    alert("Suppression echoué. Veuillez réessayer.");
                    window.location.reload();
                }
            )
        }
    }

    const handlePrint = (idRapport: number) => {
        window.open(`/repporting/print-rapport/${idRapport}`, "_blank");
    }

    return (
        !!data ? (
            <>
                <Table>
                    <TableHead
                        sx={{
                            "& *": {
                                fontSize: 20
                            }
                        }}
                    >
                        <TableCell>Nom Rapport</TableCell>
                        <TableCell>En tete du document</TableCell>
                        <TableCell
                            align="right"
                        >
                            <Box textAlign={"right"}>Action</Box>
                        </TableCell>
                    </TableHead>
                    <TableBody
                        sx={{
                            "& > *:nth-child(odd)": {
                                bgcolor: grey[200]
                            }
                        }}
                    >
                        {
                            data.map((value, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>{value.nomRapport}</TableCell>
                                    <TableCell>{value.titreDocument}</TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Stack
                                            gap={2}
                                            direction={"row"}
                                            justifyContent={"flex-end"}
                                        >
                                            <ButtonGroup variant="solid">
                                                <Button
                                                    color="danger"
                                                    onClick={() => handleDelete(value.idRapport)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>

                                                <Button
                                                    color="success"
                                                    onClick={() => handlePrint(value.idRapport)}
                                                >
                                                    <FontAwesomeIcon icon={faFileDownload} />
                                                </Button>
                                            </ButtonGroup>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </>

        ) : undefined
    )
}

export default TableZone