import { Box, Stack, Typography } from '@mui/joy';
import { COLOR } from '../../constants/constants';
import { motion } from "framer-motion";
import { Avatar } from '@mui/material';

const GroupeCibleZone = () => {
    return (
        <Stack
            gap={2}
            bgcolor={COLOR.white}
            p={3}
        >
            <Box
                width={"fit-content"}
                p={1}
            >
                <Typography
                    textColor={COLOR.blue}
                    level='h2'
                >
                    Groupe cible
                </Typography>
            </Box>

            <Stack
                bgcolor={COLOR.white}
                // p={2}
                borderRadius={20}
                direction={"row"}
                gap={4}
                flexWrap={"wrap"}
                justifyContent={"space-around"}
            >
                <Stack
                    sx={{
                        borderRadius: "30px",
                        width: `${window.innerWidth < 500 ? 100 : 49}%`,
                        minWidth: "280px",
                        transform: "translateX(-50px)"
                    }}
                    gap={2}
                >
                    <motion.div
                        whileInView={{
                            x: 50,
                            transition: {
                                delay: 0.1
                            }
                        }}
                    >
                        <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            bgcolor={COLOR.lightblue}
                            p={2}
                            borderRadius={"20px"}
                        >
                            <Avatar
                                alt="Image de jeune"
                                sx={{ width: 50, height: 50 }}
                            />
                            <Stack>
                                <Typography level="h4">15 à 40 ans</Typography>
                                <Typography> intervale d'age</Typography>
                            </Stack>
                        </Stack>
                    </motion.div>

                    <motion.div
                        whileInView={{
                            x: 50,
                            transition: {
                                delay: 0.5
                            }
                        }}
                    >
                        <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            bgcolor={COLOR.lightblue}
                            p={2}
                            borderRadius={"20px"}
                        >
                            <Avatar
                                alt="Image de jeune"
                                sx={{ width: 50, height: 50 }}
                            />
                            <Stack>
                                <Typography level="h4">Homme Femme (H&F)</Typography>
                                <Typography >Sexe ciblé</Typography>
                            </Stack>
                        </Stack>
                    </motion.div>

                    <motion.div
                        whileInView={{
                            x: 50,
                            transition: {
                                delay: 0.8
                            }
                        }}
                    >
                        <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            bgcolor={COLOR.lightblue}
                            p={2}
                            borderRadius={"20px"}
                        >
                            <Avatar
                                alt="Image de jeune"
                                sx={{ width: 50, height: 50 }}
                            />
                            <Stack>
                                <Typography level="h4">~40% de Femme</Typography>
                                <Typography >Taux de femme</Typography>
                            </Stack>
                        </Stack>
                    </motion.div>
                </Stack>
                <motion.div
                    style={{
                        minWidth: 300,
                        width: `${window.innerWidth < 500 ? 100 : 45}%`
                    }}
                    whileInView={{
                        x: -80,
                        transition: { duration: 1 }
                    }}
                >
                    <Typography
                        textAlign={"justify"}
                        sx={{
                            transform: `translateX(80px)`,
                        }}
                    >
                        Les
                        <Typography level="title-lg" > jeunes ruraux, femmes et hommes </Typography>
                        (F&H) âgés de
                        15 à 40 ans et issus de ménages à
                        <Typography level="title-lg" > faibles revenus </Typography>
                        , constituent le groupe cible du projet. Parmi eux, les jeunes de
                        <Typography level="title-lg" > 15 à 18 ans </Typography>
                        auront accès à des opportunités de formation professionnelle, puis
                        seront intégrés individuellement ou collectivement dans
                        <Typography level="title-lg" > l’économie locale.</Typography>
                        Environ 40% des bénéficiaires seront des
                        <Typography level="title-lg" > jeunes femmes.</Typography>
                    </Typography>
                </motion.div>


            </Stack>
        </Stack>
    )
}

export default GroupeCibleZone