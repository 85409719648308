import { FormControl, MenuItem, Select } from "@mui/material";
import { Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import { getAllViews } from "../../../function/getAllViews";
import { Typography } from "@mui/joy";
import { CircularProgress } from "@mui/material";

const Etape1 = ({ setViewName, viewName = null }) => {
    const [isConnectedToDb, setisConnectedToDb] = useState(false);

    const [listView, setlistView] = useState([]);

    useEffect(
        () => {
            getAllViews().then(
                (data) => {
                    setisConnectedToDb(true);
                    console.log('====================================');
                    console.log(data);
                    console.log('====================================');

                    setlistView(data);
                }
            ).catch(
                (data) => {
                    alert(`${data}. Nous allons vous rediriger vers la page d'accueil`);
                }
            )
        }, []
    )

    return (
        <Stack
            gap={2}
            minWidth={350}
            maxWidth={450}
        >
            {
                isConnectedToDb
                    ? (
                        <FormControl >
                            <Select
                                inputProps={{ 'aria-label': 'Without label' }}
                                displayEmpty
                                value={viewName}
                                onChange={(e) => setViewName && setViewName(e.target.value)}
                            >
                                <MenuItem value={null}>Aucune</MenuItem>
                                {
                                    listView.map(value => (
                                        <MenuItem value={value}>{value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    )
                    : (
                        <Stack
                            alignItems={"center"}
                            gap={2}
                        >
                            <Typography
                                level="h4"
                            >
                                Recuperation des sources de données
                            </Typography>
                            <CircularProgress />
                        </Stack>
                    )

            }

        </Stack>
    )
}

export default Etape1