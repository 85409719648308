import { Stack } from "@mui/joy";
import StatistiqueRapideZone from "./StatistiqueRapideZone";
import TableZone from "./TableZone";
import GrapheZone from "./GrapheZone";
import { useEffect, useState } from "react";
import { LISTE_DES_FICHES } from "../../constants/constants";
import CourbeZone from "./CourbeZone";
import DonutZone from "./DonutZone";

const StatistiqueDataZone = ({ fonctionNumber = 4 }) => {
    const [currentFiche, setcurrentFiche] = useState(LISTE_DES_FICHES[fonctionNumber]);
    const [elementToShow, setelementToShow] = useState(undefined);

    useEffect(
        () => {
            setcurrentFiche(
                LISTE_DES_FICHES.find(value => value.fonctionNumber == fonctionNumber)
            );
        },
        [fonctionNumber]
    );

    useEffect(
        () => {
            switch (currentFiche.element) {
                case "tableau":
                    setelementToShow(
                        <TableZone
                            fonctionNumber={fonctionNumber}
                        />
                    );
                    break;

                case "graphe":
                    setelementToShow(
                        <GrapheZone fonctionNumber={fonctionNumber} />
                    );
                    break;

                case "courbe":
                    setelementToShow(
                        <CourbeZone fonctionNumber={fonctionNumber} />
                    );
                    break;

                case "donut":
                    setelementToShow(
                        <DonutZone fonctionNumber={fonctionNumber} />
                    )
            }
        },
        [currentFiche]
    )

    return (
        <Stack
            width={"100%"}
            minWidth={window.innerWidth < 900 && "100%"}
            gap={2}
        >
            <StatistiqueRapideZone />
            {
                elementToShow
            }
        </Stack>
    )
}

export default StatistiqueDataZone