import { Box, Card, CardCover, Link, Stack, Typography } from "@mui/joy";
import { COLOR } from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faInfoCircle, faLocationArrow, faMoneyBillTransfer, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Divider } from "@mui/material";
import QRCode from "react-qr-code";
import { useState } from "react";

export const BeneficiaireCard = ({ nom, dateNaissance, tel, location, projet, CNI, etude, organisation, sexe, id, photo }) => {
    const [showQrCode, setshowQrCode] = useState(false);

    return (
        <Stack
            gap={2}
            bgcolor={COLOR.white}
            borderRadius={15}
            width={300}
        >
            <Stack
                direction={"row"}
                gap={1}
            >
                <Card
                    sx={{
                        width: 80,
                        minWidth: 80,
                        height: 80,
                        minHeight: 80,
                        transition: "ease .6s",
                        transform: `rotateY(${showQrCode ? 360 : 0}deg)`
                    }}
                    onMouseUp={() => setshowQrCode(!showQrCode)}
                >
                    <CardCover>
                        {
                            showQrCode
                                ? (
                                    <QRCode
                                        value={`https://fier2.fidamali.org/carte_ind2_g.php?id=${id}`}
                                    />
                                )
                                : (
                                    <img
                                        src={photo}
                                        style={{
                                            objectFit: "contain"
                                        }}
                                    />
                                )
                        }
                    </CardCover>
                </Card>

                <Stack
                    justifyContent={"space-between"}
                >
                    <Typography level="h4">{nom || "Prenom nom"}</Typography>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        title={"Date de naissance"}
                    >
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <Typography>{dateNaissance || "Date de naissance"} ({sexe == "F" ? "Femme" : "Homme"})</Typography>
                    </Stack>
                    <Link
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        title={"Contact"}
                        href={`tel:${tel}`}
                    >
                        <FontAwesomeIcon icon={faPhoneAlt} />
                        <Typography>{tel || "Numero de téléphone"}</Typography>
                    </Link>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        title={"Contact"}
                    >
                        <FontAwesomeIcon icon={faLocationArrow} />
                        <Typography fontSize={11} >{location || "Bamako Kalaban"}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack>
                <Box
                    sx={{
                        "& :last-child:is(hr)": {
                            my: 1
                        },
                        "& > span :first-child": {
                            fontWeight: 700
                        },
                    }}
                >
                    {
                        projet && (
                            <Typography>
                                <Typography level="h4">Idée de projet: </Typography>
                                <Typography>{projet}</Typography>
                                <Divider />
                            </Typography>
                        )
                    }
                    {
                        CNI && (
                            <Typography>
                                <Typography level="h4">Numero CNI: </Typography>
                                <Typography>{CNI}</Typography>
                                <Divider />
                            </Typography>
                        )
                    }
                    {
                        etude && (
                            <Typography>
                                <Typography level="h4">Niveau d'étude: </Typography>
                                <Typography>{etude}</Typography>
                                <Divider />
                            </Typography>
                        )
                    }
                    {
                        organisation && (
                            <Typography>
                                <Typography level="h4">Organisation: </Typography>
                                <Typography>{organisation}</Typography>
                            </Typography>
                        )
                    }
                </Box>
                <ButtonGroup
                    fullWidth
                    sx={{
                        width: "100%",
                        "& > *:is(a, button)": {
                            gap: 1,
                            color: COLOR.white,
                            textTransform: "lowercase"
                        },
                        "& *:hover": {
                            color: COLOR.blue,
                        },
                    }}
                >
                    <Button
                        sx={{
                            bgcolor: COLOR.green,
                        }}
                        target="_blank"
                        href={`https://fier2.fidamali.org/carte_ind2_g.php?id=${id}`}
                    >
                        Voir sa fiche
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Button>
                    <Button
                        sx={{
                            bgcolor: COLOR.blue,
                        }}
                    >
                        Financement
                        <FontAwesomeIcon icon={faMoneyBillTransfer} />
                    </Button>
                </ButtonGroup>
            </Stack>
        </Stack>
    )
}
