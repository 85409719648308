import axios from "axios";
import { FIELDS_NEW_NAME_LISTE_T, FILTER_TABLE_T } from "../constant/objectType";

/**
 * Creates a request to generate a report based on the provided parameters.
 * 
 * @param {string} viewName - The name of the view to query.
 * @param {string} filterByValue - The field to filter the results by.
 * @param {boolean} isCroissant - Whether to sort the results in ascending (true) or descending (false) order.
 * @param {string|null} groupeByValue - The field to group the results by, or null if no grouping is desired.
 * @param {FIELDS_NEW_NAME_LISTE_T[]} fieldNewNameList - A list of field names to rename, with their original and edited names.
 * @param {FILTER_TABLE_T[]} functionTable - A list of functions to apply to the results, with their operation, field, and value.
 * @param {string} nomRapport - The name of the report.
 * @param {string} enTeteDocument - The header of the document.
 * @param {string} piedDocument - The footer of the document.
 * @param {string} titreDocument - The title of the document.
 * @param {string} couleurLigneFiltre - The color of the filter line.
 * @param {boolean} isAllConditionTrue - Whether all conditions must be true for the filter to apply.
 * @param {number} pagination - The number of results to display per page.
 * @param {string} request - The request to send to the server.
 * @param {string} bd_url - The URL of the database.
 * @param {string} bd_name - The name of the database.
 * @param {string} bd_login - The login credentials for the database.
 * @param {string} bd_pass - The password for the database.
 * @param {string} categorie - The category of the report.
 * @param {string} visibilite - The visibility of the report.
 * @param {FILTER_TABLE_T[]} filterTable - A list of filters to apply to the results.
 * 
 * @returns {Promise<any>} A promise that resolves with the response data from the server.
 * 
 * @example
 * createRequest(
 *   'my_view',
 *   'my_field',
 *   true,
 *   'my_group_by_field',
 *   [
 *     { orignalName: 'my_field', editedName: 'My Field' },
 *     { orignalName: 'my_other_field', editedName: 'My Other Field' },
 *   ],
 *   [
 *     { operation: 'SUM', field: 'my_field', value: 'my_sum' },
 *     { operation: 'COUNT', field: 'my_other_field', value: 'my_count' },
 *   ],
 *   'My Report',
 *   'My Header',
 *   'My Footer',
 *   'My Title',
 *   'red',
 *   true,
 *   10,
 *   'my_request',
 *   'https://example.com/db',
 *   'my_db',
 *   'my_login',
 *   'my_pass',
 *   'my_category',
 *   'public',
 *   [
 *     { field: 'my_field', value: 'my_value' },
 *     { field: 'my_other_field', value: 'my_other_value' },
 *   ],
 * ).then((data) => console.log(data)).catch((error) => console.error(error));
 */
export const createRequest = (
    viewName: string,
    filterByValue: string,
    isCroissant: boolean,
    groupeByValue: string | null,
    fieldNewNameList: FIELDS_NEW_NAME_LISTE_T[],
    functionTable: FILTER_TABLE_T[],
    nomRapport: string,
    enTeteDocument: string,
    piedDocument: string,
    titreDocument: string,
    couleurLigneFiltre: string,
    isAllConditionTrue: boolean,
    pagination: number,
    categorie: string,
    visibilite: string,
    filterTable: FILTER_TABLE_T[],
    bd_url: string | undefined = process.env.REACT_APP_SISE_BD_URL,
    bd_name: string | undefined = process.env.REACT_APP_SISE_BD_NAME,
    bd_login: string | undefined = process.env.REACT_APP_SISE_BD_LOGIN,
    bd_pass: string | undefined = process.env.REACT_APP_SISE_BD_PASS,
): Promise<any> => {
    return new Promise((resolve, reject) => {
        let fields: string = ``;

        fieldNewNameList.forEach((value, index) => {
            fields += `${value.orignalName} AS \`${value.editedName}\` ${index < fieldNewNameList.length - 1 ? ',' : ""}`
        });

        functionTable.forEach((el, index) => {
            fields += `, ${el.operation}(${el.field}) AS \`${el.value}\` ${index < functionTable.length - 1 ? ',' : ""}`
        });

        let query = `
            SELECT ${fields} 
            FROM ${viewName} 
            ${!!groupeByValue ? `GROUP BY ${groupeByValue}` : ''}  
            ORDER BY ${filterByValue} ${isCroissant ? 'ASC' : 'DESC'}
        `;

        console.log('====La requete======================');
        console.log(query);
        console.log('====================================');

        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/rapport/createRapport.php`,
            {
                nomRapport: nomRapport,
                enTeteDocument: enTeteDocument,
                piedDocument: piedDocument,
                titreDocument: titreDocument,
                couleurLigneFiltre: couleurLigneFiltre,
                isAllConditionTrue: isAllConditionTrue,
                pagination: pagination,
                request: query,
                bd_url: bd_url,
                bd_name: bd_name,
                bd_login: bd_login,
                bd_pass: bd_pass,
                categorie: categorie,
                visibilite: visibilite,
                filterTable: filterTable,
            }
        ).then(
            ({ data }) => {
                console.log('====================================');
                console.log(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            res => {
                console.log('====================================');
                console.error(res);
                console.log('====================================');

                reject(res);
            }
        )

        return query;
    })


}