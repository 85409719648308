import { faSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, Switch, Typography } from "@mui/joy";
import { FormControl, FormLabel, Input, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { COLOR } from "../../../constant";

const filterOptions = [
    {
        text: "Somme",
        value: "SUM",
    },
    {
        text: "Compte",
        value: "COUNT",
    },
    {
        text: "Moyenne",
        value: "AVG",
    },
    {
        text: "Maximum",
        value: "MAX",
    },
    {
        text: "Minimum",
        value: "MIN",
    },
]

const Etape6 = ({ fieldNewNameList = [], functionTable = [], setfunctionTable }) => {
    const [currentField, setcurrentField] = useState(null);
    const [currentFunction, setcurrentFunction] = useState("SUM");
    const [currentFieldNewName, setcurrentFieldNewName] = useState(null);

    const addElement = () => {
        if (currentField && currentFunction && currentFieldNewName) {
            setfunctionTable && setfunctionTable([
                ...functionTable,
                {
                    field: currentField,
                    operation: currentFunction,
                    value: currentFieldNewName
                }
            ]);

            console.log('====================================');
            console.table(functionTable);
            console.log('====================================');

            setcurrentField(null);
            setcurrentFunction("SUM");
            setcurrentFieldNewName(null);
        }
    }

    const deleteAnElement = (i) => {
        let data = [];

        functionTable.map((value, index) => {
            index != i && data.push(value);
        })

        setfunctionTable && setfunctionTable(data);
    }

    useEffect(
        () => {

        },
        [currentFunction]

    )

    return (
        <Stack
            gap={2}
        >
            <FormControl >
                <FormLabel>Selectionnez le champ à filtrer</FormLabel>
                <Select
                    // inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    sx={{
                        minWidth: 350
                    }}
                    value={currentField}
                    onChange={({ target }) => setcurrentField(target.value)}
                >
                    <MenuItem value={null}>Aucune</MenuItem>
                    {
                        fieldNewNameList.map((value, index) => (
                            <MenuItem
                                key={index}
                                value={value.orignalName}
                            >
                                {value.editedName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <FormControl >
                <FormLabel>Selectionnez une fonction</FormLabel>
                <Select
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    sx={{
                        minWidth: 350
                    }}
                    value={currentFunction}
                    onChange={({ target }) => setcurrentFunction(target.value)}
                >
                    {
                        filterOptions.map((value, index) => (
                            <MenuItem
                                key={index}
                                value={value.value}
                            >
                                {`${value.text} (${value.value})`}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <Stack
                gap={1}
                direction={"row"}
            >
                <TextField
                    label={"Comment voulez vous renommer votre champ ?"}
                    value={currentFieldNewName}
                    fullWidth
                    onChange={({ target }) => setcurrentFieldNewName(target.value)}
                />
                <Button
                    onClick={() => addElement()}
                    disabled={!currentFieldNewName || !currentField}
                >Ajouter</Button>
            </Stack>

            {
                functionTable.length > 0 && (
                    <Stack>
                        <Typography>Resumé</Typography>
                        <Box
                            p={1}
                            bgcolor={COLOR.blue}
                            color={COLOR.white}
                            borderRadius={5}
                        >
                            <Typography textColor={COLOR.white}>
                                {
                                    functionTable.map((value, index) => (
                                        <Typography>
                                            <Typography
                                                fontWeight={700}
                                                textColor={COLOR.black}
                                            >{value.value}</Typography>
                                            {` `} = {` `}
                                            <Typography
                                                fontWeight={700}
                                                textColor={COLOR.black}
                                            >{value.operation}</Typography>
                                            (
                                            <Typography
                                                fontWeight={700}
                                                textColor={COLOR.black}
                                            >{value.field}</Typography>
                                            )

                                            <Button
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    p: 1,
                                                    mx: 1,
                                                    bgcolor: "red",
                                                    transition: `.1s all`,
                                                    ":hover": {
                                                        bgcolor: "red",
                                                        transform: `scale(1.1)`,
                                                    }
                                                }}
                                                onClick={() => { deleteAnElement(index) }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                />
                                            </Button>
                                            {
                                                index < functionTable.length - 1 && (<br />)
                                            }
                                        </Typography>
                                    ))
                                }
                            </Typography>

                            <Stack>
                                <Button
                                    sx={{
                                        p: 1,
                                        bgcolor: "red",
                                        transition: `.1s all`,
                                        ":hover": {
                                            bgcolor: "red",
                                            transform: `scale(1.05)`,
                                        },
                                        mt: 2,
                                        gap: 1,
                                        alignSelf: "flex-end"
                                    }}

                                    onClick={() => setfunctionTable([])}

                                >
                                    Tout supprimer
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            }

        </Stack>
    )
}

export default Etape6