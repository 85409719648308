import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/joy';
import { COLOR } from '../../constants/constants';
import { CircularProgress, LinearProgress } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: COLOR.lightblue,
        color: COLOR.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function TableModel({ header, data, tableTilte, maxWidth, height }) {
    return (
        <TableContainer
            component={Paper}
            sx={{
                borderRadius: 5,
                width: "fit-content",
                maxWidth: maxWidth || "100%",
                minWidth: 350,
                height: height || "fit-content",
                maxHeight: 700,
                p: 1,
            }}
            elevation={5}

        >
            <Typography
                fontWeight={700}
                textAlign={"justify"}
                maxWidth={"100%"}
                textColor={COLOR.black}
                mb={2}
                borderRadius={15}
            >
                {
                    tableTilte || <LinearProgress />
                }
            </Typography>

            <Table
                sx={{
                    minWidth: 330,
                    maxWidth: "100%",
                    // m:1,
                }}
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow>
                        {
                            (!!header && header != [])
                                ? (
                                    header.map((value, index) => (
                                        <StyledTableCell key={index}>
                                            {value}
                                        </StyledTableCell>
                                    ))
                                ) : (
                                    <CircularProgress />
                                )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (!!data && data != []) ? (
                            data.map((value, index) => (
                                <StyledTableRow
                                    key={index}
                                    sx={{
                                        ":hover": {
                                            bgcolor: COLOR.blue,
                                        }
                                    }}
                                >
                                    {
                                        value.map((ligne, indexTmp) => (
                                            indexTmp < header.length && <StyledTableCell key={indexTmp}>
                                                {ligne}
                                            </StyledTableCell>
                                        ))
                                    }
                                </StyledTableRow>
                            ))
                        ) : (
                            <LinearProgress />
                        )
                    }
                </TableBody>
            </Table>

        </TableContainer>
    );
}
