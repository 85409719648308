import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Typography } from "@mui/joy";
import { TableCell, TableRow } from "@mui/material";
import { useState } from "react";

export const Field = ({ name, setfieldNewNameList, fieldNewNameList = [], index }) => {
    const [newName, setnewName] = useState(name);

    const handleChange = (target) => {
        setnewName(target.value);

        let result = [];

        fieldNewNameList.map((value, key) => {
            result.push(
                key === index
                    ? {
                        orignalName : value.orignalName,
                        editedName : target.value,
                    }
                    : value
            );

            setfieldNewNameList(result);
        })
    }

    return (
        <TableRow>
            <TableCell>
                <Typography>
                    {name}
                </Typography>
            </TableCell>
            <TableCell>
                <Input
                    value={newName || name}
                    onChange={({ target }) => handleChange(target)}
                    endDecorator={
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            title={`Le champ ${name} va se renommer ${newName} sur vos interfaces`}
                        />
                    }
                />
            </TableCell>
        </TableRow>
    )
}