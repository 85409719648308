import { Stack } from "@mui/joy"
import EtapeIndex from "./EtapeIndex"
import { COLOR } from "../../constant"

const EtapeIndexZone = ({ currentIndex, etapeListe = [] }) => {
    return (
        <Stack
            position={"fixed"}
            bottom={"0"}
            width={"100%"}
            bgcolor={"white"}
            borderTop={`1px solid ${COLOR.blue}`}
            py={1}

            zIndex={3}
        >
            <Stack
                direction={"row"}
                justifyContent={currentIndex < 6 && "center"}
                gap={3}
                sx={{
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                        display: "none"
                    }
                }}
                px={2}
            >
                {
                    etapeListe.map((value, index) => (
                        value.index <= currentIndex && (
                            <EtapeIndex
                                index={value.index}
                                title={value.title}
                                key={index}
                                checked={value.index < currentIndex}
                            />
                        )
                    ))
                }
            </Stack>
        </Stack >
    )
}

export default EtapeIndexZone