import axios from "axios";

export const deleteRapport = (idrapport: number) => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/rapport/deleteRapport.php`,
            {
                idRapport: idrapport
            }
        ).then(
            ({ data }) => {
                console.log('====================================');
                console.log(data);
                console.log('====================================');

                resolve(true);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}