import { Box } from '@mui/joy'
import { useEffect, useState } from 'react'
import { identificationBeneficiaires } from '../../functions/fiches/listeBeneficiaires/identificationBeneficiaires'
import { COLOR } from '../../constants/constants'
import { CourbeModel } from '../../components/CourbeModel/CourbeModel'

const CourbeZone = ({ fonctionNumber }) => {
    const [graphXaxis, setgraphXaxis] = useState([])
    const [graphYaxis, setgraphYaxis] = useState([])
    const [graphDataMultiLine, setgraphDataMultiLine] = useState([{ data: [] }]);
    const [graphTitle, setgraphTitle] = useState(null)
    const [isShowArea, setisShowArea] = useState(true);
    const [dataIsMultiLine, setdataIsMultiLine] = useState(false);

    useEffect(
        () => {
            setgraphXaxis([]);
            setgraphTitle(null);

            switch (fonctionNumber) {
                case 6:
                    setgraphXaxis(["Cultures maraicheres", "Ovins", "Distribution", "Riz", "Bovins"]);
                    setgraphTitle("Nombre de beneficiaires par secteur d'activité");

                    setdataIsMultiLine(false);

                    identificationBeneficiaires().then(
                        data => {
                            setgraphYaxis([]);

                            let dataTmp = [0, 0, 0, 0, 0];

                            data.map(value => {
                                dataTmp[0] = dataTmp[0] + (value.Domaines == "Cultures maraicheres" ? 1 : 0);
                                dataTmp[1] = dataTmp[1] + (value.Domaines == "Ovins" ? 1 : 0);
                                dataTmp[2] = dataTmp[2] + (value.Domaines == "Distribution" ? 1 : 0);
                                dataTmp[3] = dataTmp[3] + (value.Domaines == "Riz" ? 1 : 0);
                                dataTmp[4] = dataTmp[4] + (value.Domaines == "Bovins" ? 1 : 0);
                            })

                            setgraphYaxis(dataTmp);
                        }
                    )
                    break;
            }
        },
        [fonctionNumber]
    )

    return (
        <Box
            overflow={"scroll"}
            borderRadius={10}
        >
            <CourbeModel
                graphTilte={graphTitle}
                xAxis={graphXaxis}
                yAxis={graphYaxis}
                isShowArea={isShowArea}
                dataIsMultiLine={dataIsMultiLine}
                maxWidth={"100%"}
                dataMulitiline={graphDataMultiLine}
                bgColor={COLOR.lightblue}
                borderRadius={2}
            />
        </Box>
    )
}

export default CourbeZone