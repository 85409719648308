import axios from "axios";

export const getAllViews = () => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/connectToDb.php`,
            {
                bd_url: process.env.REACT_APP_SISE_BD_URL,
                bd_name: process.env.REACT_APP_SISE_BD_NAME,
                bd_login: process.env.REACT_APP_SISE_BD_LOGIN,
                bd_pass: process.env.REACT_APP_SISE_BD_PASS,
            }
        ).then(
            ({data}) => {
                console.log('====================================');
                console.log(data);
                console.log('====================================');

                let response : string[] = [];

                data.map((value: any) => {
                    response.push(value[`Tables_in_${process.env.REACT_APP_SISE_BD_NAME}`])
                })

                resolve(response);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}