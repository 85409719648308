import { Stack, Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = ({icon, text}) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            p={1}
            bgcolor={COLOR.white}
        >
            <FontAwesomeIcon icon={icon || faPhoneAlt} color={COLOR.blue}/>

            <Typography
                fontWeight={700}
            >
                {text || "+223 xx-xx-xx-xx"}
            </Typography>
        </Stack>
    )
}

export default Contact