import { Avatar, CircularProgress, Stack, Typography } from "@mui/joy"
import { useEffect, useState } from "react"
import { createRequest } from "../../../function/createRequest"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Etape8 = (
    {
        viewName,
        filterByValue,
        isCroissant = true,
        groupeByValue,
        fieldNewNameList = [],
        functionTable = [],
        rapportName,
        enTeteDocument,
        piedDocument,
        titreDocument,
        couleurLigneFiltre,
        isAllConditionTrue,
        pagination,
        categorie,
        visibilite,
        filterTable = [],
        isRapportCreated = null,
        setisRapportCreated,
        setidRapport
    }
) => {
    useEffect(
        () => {
            createRequest(
                viewName,
                filterByValue,
                isCroissant,
                groupeByValue,
                fieldNewNameList,
                functionTable,
                rapportName,
                enTeteDocument,
                piedDocument,
                titreDocument,
                couleurLigneFiltre,
                isAllConditionTrue,
                pagination,
                categorie,
                visibilite,
                filterTable
            ).then(
                (data) => {
                    setisRapportCreated(true);
                    setidRapport(data);
                }
            ).catch(
                () => setisRapportCreated(false)
            )
        },
        []
    );

    return (
        <Stack
            gap={2}
            alignItems={"center"}
        >
            {
                isRapportCreated == true
                    ? (
                        <>
                            <Avatar color="primary" size="lg">
                                <FontAwesomeIcon
                                    style={{ justifySelf: "center" }}
                                    icon={faCheckCircle}
                                />
                            </Avatar>
                            <Typography level="h4" sx={{ mb: 2 }}>
                                Rapport creé avec succès.
                            </Typography>
                        </>
                    )
                    : isRapportCreated == null
                        ? (
                            <>
                                <Typography level="h4" sx={{ mb: 2 }}>
                                    Compilation des données en cours
                                </Typography>

                                <CircularProgress />
                            </>
                        )
                        : (
                            <>
                                <FontAwesomeIcon
                                    style={{ justifySelf: "center" }}
                                    icon={faTriangleExclamation}
                                />
                                <Typography level="h4" sx={{ mb: 2 }}>
                                    Une erreur est survenue. Veuillez reessayeer
                                </Typography>
                            </>
                        )
            }
        </Stack>
    )
}

export default Etape8