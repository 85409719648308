import axios from "axios";

export const getViewColumns = (viewName: string) => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_SISE_API_URL}/getViewColumns.php`,
            {
                bd_url: process.env.REACT_APP_SISE_BD_URL,
                bd_name: process.env.REACT_APP_SISE_BD_NAME,
                bd_login: process.env.REACT_APP_SISE_BD_LOGIN,
                bd_pass: process.env.REACT_APP_SISE_BD_PASS,
                viewName: viewName,
            }
        ).then(
            (res) => {
                console.log('====================================');
                console.log(res);
                console.log('====================================');

                let response: string[] = [];

                res.data.map((value: any) => {
                    response.push(value.Field);
                })

                resolve(response);
            }
        ).catch(
            (error) => {
                console.log('====================================');
                console.error(error);
                console.log('====================================');

                reject("Impossible de se connecter");
            }
        )
    })
}