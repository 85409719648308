import { faArrowDown19, faArrowDown91 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack } from '@mui/joy';
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { COLOR } from '../../../constant';
import { blue } from '@mui/material/colors';

const Etape5 = ({ fieldToShow = [], filterByValue, groupeByValue, setfilterByValue, setgroupeByValue, isCroissant, setisCroissant }) => {

    return (
        <Stack
            gap={2}
            sx={{
                minWidth: 350
            }}
        >
            <FormControl>
                <FormLabel>Filter les données en fonction du</FormLabel>
                <Stack
                    gap={1}
                    p={1}
                    bgcolor={blue[100]}
                    borderRadius={10}
                >
                    <Select
                        inputProps={{ 'aria-label': 'Without label' }}
                        displayEmpty
                        value={filterByValue}
                        sx={{
                            bgcolor: COLOR.white
                        }}
                        onChange={({ target }) => setfilterByValue && setfilterByValue(target.value)}
                    >
                        <MenuItem value={null} selected>Aucun</MenuItem>

                        {
                            fieldToShow.map((value, index) => (
                                <MenuItem
                                    value={value.orignalName}
                                    key={index}
                                >
                                    {value.editedName}
                                </MenuItem>
                            ))
                        }
                    </Select>

                    <Button
                        endDecorator={
                            <FontAwesomeIcon icon={isCroissant ? faArrowDown19 : faArrowDown91} />
                        }

                        onClick={() => setisCroissant && setisCroissant(!isCroissant)}
                    >
                        {
                            isCroissant ? `Trier par order croissant` : `Trier par order décroissant`
                        }
                    </Button>
                </Stack>

            </FormControl>

            <FormControl>
                <FormLabel>Grouper les données en fonction du</FormLabel>
                <Select
                    displayEmpty
                    value={groupeByValue}
                    onChange={({ target }) => setgroupeByValue && setgroupeByValue(target.value)}
                >
                    <MenuItem selected value={null}>Aucun</MenuItem>

                    {
                        fieldToShow.map((value, index) => (
                            <MenuItem
                                value={value.orignalName}
                                key={index}
                            >
                                {value.editedName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Stack>
    )
}

export default Etape5