import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import { COLOR } from '../../constants/constants'

const ButtonIcon = ({ icon, href, sx }) => {
    return (
        <Button
            href={href}
            sx={[{
                textAlign: "center",
                border: `3px solid ${COLOR.black}`,
                bgcolor: COLOR.blue,
                height: 43,
                width: 43,
                borderRadius: 50,
                color: COLOR.white,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                p: 0
            }, sx]}

        >
            <FontAwesomeIcon icon={icon || faPhoneAlt} />
        </Button>
    )
}

export default ButtonIcon